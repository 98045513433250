import { gql } from '@/__generated__';

const GET_DGA_REPORTS = gql(`#graphql
  query DgaList_GetDGAReports($deviceIdString: String!, $deviceId: ID!, $start: Float!, $stop: Float!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        name
      }
      dgaConfiguration {
        id
        type
      }
    }
    GetDGAReports(deviceId: $deviceIdString, start: $start, stop: $stop) {
      DeviceID
      timestamp
      execution_timestamp
      success
      values {
        flow
        level
        accumulated_volume
      }
      error_message
    }
  }
`);

export default GET_DGA_REPORTS;
