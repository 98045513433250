// react
import { useMemo, useState } from 'react';
// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';

// utils

// graphql
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_DEVICE_ALERTS from '../graphql/mutations/updateDeviceAlerts';

// components
import { DeviceDialogProps } from '../../../components/optionsMenu/dialogInterfaces';
import toast from 'react-hot-toast';
import DISABLE_DEVICE_ALERTS from '../graphql/mutations/disableDeviceAlerts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormInputText, FormSelect } from 'src/components/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormInput, formSchema } from '../utils/formSchema';
import { AlertTypes, MetricUnit } from 'src/__generated__/graphql';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import GET_CURRENT_DEVICE_ALERTS from '../graphql/queries/getDeviceAlerts';

const CreateDeviceAlerts = ({ show, device, handleClose }: DeviceDialogProps) => {
  const { _ } = useLingui();
  const { data, loading } = useQuery(GET_CURRENT_DEVICE_ALERTS, {
    variables: {
      input: { id: device.id },
      flowUnit: MetricUnit.LiterPerSecond,
      levelUnit: MetricUnit.Centimeter,
    },
  });

  const initialAlert = useMemo(() => {
    const currentDeviceAlerts = data?.device.alerts;
    if (!currentDeviceAlerts) {
      return null;
    }
    const { lastFlow, lastLevel } = currentDeviceAlerts;
    if (lastLevel != null) {
      return AlertTypes.Level;
    }
    if (lastFlow != null) {
      return AlertTypes.Flow;
    }
    return null;
  }, [data]);

  const deviceAlerts = data?.device.alerts;
  const { control, formState, handleSubmit, watch } = useForm<IFormInput>({
    defaultValues: {
      alertType: AlertTypes.Level,
    },
    values: {
      alertType: initialAlert ?? AlertTypes.Level,
      levelMin: deviceAlerts?.lastLevel?.min ?? undefined,
      levelMax: deviceAlerts?.lastLevel?.max ?? undefined,
      levelOverflow: deviceAlerts?.lastLevel?.overflow ?? undefined,
      flowMin: deviceAlerts?.lastFlow?.min ?? undefined,
      flowMax: deviceAlerts?.lastFlow?.max ?? undefined,
      flowOverflow: deviceAlerts?.lastFlow?.overflow ?? undefined,
    },
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const [executeUpdateDeviceAlerts, { loading: updateLoading }] = useMutation(
    UPDATE_DEVICE_ALERTS,
    { refetchQueries: [GET_CURRENT_DEVICE_ALERTS] },
  );
  const [executeDisableDeviceAlerts, { loading: disableLoading }] =
    useMutation(DISABLE_DEVICE_ALERTS);
  const [showDesactivateConfirmation, setShowDesactivateConfirmation] = useState(false);

  const handleDisableAlerts = async () => {
    try {
      await executeDisableDeviceAlerts({ variables: { deviceId: device.id } });
      toast.success(_(msg`Actualizado correctamente`));
      handleInternalClose();
    } catch (e) {
      toast.error(_(msg`Hubo un error al actualizar`));
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = async (formData) => {
    const { alertType, levelMin, levelMax, levelOverflow, flowMin, flowMax, flowOverflow } =
      formData;

    let min: number;
    let max: number;
    let overflow: number;
    let unit;

    if (alertType === AlertTypes.Level) {
      min = levelMin as number;
      max = levelMax as number;
      overflow = levelOverflow as number;
      unit = 'cm';
    } else if (alertType === AlertTypes.Flow) {
      min = flowMin as number;
      max = flowMax as number;
      overflow = flowOverflow as number;
      unit = 'l/s';
    } else {
      return;
    }

    try {
      await executeUpdateDeviceAlerts({
        variables: {
          deviceId: device.id,
          alertType: alertType,
          min: min,
          max: max,
          overflow: overflow,
          unit: unit,
        },
      });
      toast.success(_(msg`Actualizado correctamente`));
      handleInternalClose();
    } catch (e) {
      toast.error(_(msg`Hubo un error al actualizar`));
    }
  };

  const handleInternalClose = () => {
    setShowDesactivateConfirmation(false);
    handleClose(false);
  };

  return (
    <Dialog open={show} onClose={() => handleInternalClose()}>
      <DialogTitle>
        <Typography>
          <Trans>Editar alertas</Trans>
        </Typography>
        <Typography color="textSecondary">
          <Trans>Dispositivo</Trans>: {device?.name}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        {loading ? (
          <Grid container flexDirection="column" justifyContent="center" alignItems="center" p={5}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
              }}
            >
              <Trans>Cargando...</Trans>
            </Typography>
            <CircularProgress />
          </Grid>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                <FormSelect
                  label={_(msg`Tipo de alerta`)}
                  name="alertType"
                  control={control}
                  options={[
                    { label: _(msg`Nivel`), value: AlertTypes.Level },
                    { label: _(msg`Caudal`), value: AlertTypes.Flow },
                  ]}
                />

                {watch('alertType') === AlertTypes.Level && (
                  <>
                    <FormInputText
                      name="levelMin"
                      control={control}
                      label={_(msg`Nivel mínimo (cm)`)}
                      type="number"
                    />

                    <FormInputText
                      name="levelMax"
                      control={control}
                      label={_(msg`Nivel máximo (cm)`)}
                      type="number"
                    />

                    <FormInputText
                      name="levelOverflow"
                      control={control}
                      label={_(msg`Nivel desborde (cm)`)}
                      type="number"
                    />
                  </>
                )}

                {watch('alertType') === AlertTypes.Flow && (
                  <>
                    <FormInputText
                      name="flowMin"
                      control={control}
                      label={_(msg`Caudal mínimo (L/s)`)}
                      type="number"
                    />

                    <FormInputText
                      name="flowMax"
                      control={control}
                      label={_(msg`Caudal máximo (L/s)`)}
                      type="number"
                    />

                    <FormInputText
                      name="flowOverflow"
                      control={control}
                      label={_(msg`Caudal desborde (L/s)`)}
                      type="number"
                    />
                  </>
                )}
              </Box>

              <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => handleInternalClose()}
                  sx={{
                    width: 100,
                  }}
                >
                  <Trans>Cancelar</Trans>
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={!formState.isValid}
                  sx={{ ml: 2, width: 100 }}
                >
                  <Trans>Guardar</Trans>
                </Button>
              </Grid>

              {initialAlert != null && (
                <Grid item xs={12} mt={2}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setShowDesactivateConfirmation(true)}
                    fullWidth
                  >
                    <Trans>Desactivar alertas</Trans>
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      <Dialog open={showDesactivateConfirmation}>
        <DialogTitle>
          <Trans>
            ¿Está seguro que desea desactivar las alertas del dispositivo {device?.name ?? ''}?
          </Trans>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="info"
              onClick={() => setShowDesactivateConfirmation(false)}
              sx={{
                mr: 2,
              }}
            >
              <Trans>Cancelar</Trans>
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={updateLoading || disableLoading}
              onClick={() => handleDisableAlerts()}
            >
              <Trans>Desactivar</Trans>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default CreateDeviceAlerts;
