import { gql } from '@/__generated__';

const GET_DEVICE_LEVEL_CALIBRATION = gql(`#graphql
  query DeviceCalibration_GetLevelCalibration($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        sensor
      }
      dataConfiguration {
        deviceId
        calibration {
          level
        }
      }
    }
  }
`);

export default GET_DEVICE_LEVEL_CALIBRATION;
