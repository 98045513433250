import { gql } from 'src/__generated__';

const AUTHENTICATE_USER = gql(`
  mutation AuthenticateUser($email: String, $password: String, $iv: String) {
    authenticateUser(email: $email, password: $password, iv: $iv) {
      name
      lastnames
      user {
        id
        email
        username
        name
        lastnames
        phone
        phone_number
        calling_code
        token
        picture
        organization_id
        updatedPassword
        organizationsArray
        lock_test
        verify_auth
        status
        access {
          controlCenter
          allDevices
          stations
          users
          virtualStations
          integrations
          dataAnalytics
          gates
          systems
          distribution
          dgaReports
          dataExtraction
          deviceConfiguration
          faq
          dataTables
          offLogin
          permits
          apiCapta
          distributionMaipo
          metricView
          deviceDiagnostics
          sendCommand
          channelNetwork
          audit
          standardDesign
          announcements
          notifications
          organizations
          billing
          alerts
          scheduledReports
        }
        role {
          id
          name
        }
        organizations {
          id
          name
          role {
            id
            name
          }
        }
      }
    }
  }
`);

export default AUTHENTICATE_USER;
