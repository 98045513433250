import {
  Wifi,
  NotificationsActive,
  Troubleshoot,
  LocationOff,
  ConfirmationNumber,
} from '@mui/icons-material';
import { Link, Avatar, Typography, Tooltip, AvatarGroup } from '@mui/material';
import { Box } from '@mui/system';
import { type FC } from 'react';

interface Props {
  deviceId: string;
}

const DeviceProfileStatus: FC<Props> = ({ deviceId }) => {
  const statusItems = [
    {
      icon: <Wifi />,
      color: 'success.main',
      title: 'Conectado recientemente',
      description: 'Último dato hace x minutos',
    },
    {
      icon: <Troubleshoot />,
      color: 'warning.main',
      title: 'Curvas de aforo no configuradas',
      description: (
        <Link target="_blank" href={`/devices/${deviceId}`} color="inherit">
          Ver detalles
        </Link>
      ),
    },
    {
      icon: <NotificationsActive />,
      color: 'primary.main',
      title: 'Alertas configuradas',
      description: (
        <Link target="_blank" href={`/devices/${deviceId}`} color="inherit">
          Ver detalles
        </Link>
      ),
    },
    {
      icon: <LocationOff />,
      color: 'warning.main',
      title: 'Ubicación no configurada',
      description: (
        <Link target="_blank" href={`/devices/${deviceId}`} color="inherit">
          Ver detalles
        </Link>
      ),
    },
    {
      icon: <ConfirmationNumber />,
      color: 'warning.main',
      title: '6 eventos los últimos 7 días',
      description: (
        <Link target="_blank" href={`/devices/${deviceId}`} color="inherit">
          Ver detalles
        </Link>
      ),
    },
  ];

  return (
    <Box display="flex" px={2} gap={4} alignSelf={{ sx: 'start', sm: 'center' }}>
      <AvatarGroup>
        {statusItems.map((item) => (
          <Tooltip
            title={
              <Box>
                <Typography fontWeight="bold" lineHeight={1.2}>
                  {item.title}
                </Typography>
                <Typography color="text.info" lineHeight={1.2}>
                  {item.description}
                </Typography>
              </Box>
            }
          >
            <Avatar sx={{ bgcolor: item.color }}>{item.icon}</Avatar>
          </Tooltip>
        ))}
      </AvatarGroup>
    </Box>
  );
};

export default DeviceProfileStatus;
