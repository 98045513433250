import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export const getUnitTranslation = (
  unit: string,
  unitDisplay: Intl.NumberFormatOptions['unitDisplay'] | null,
  i18n: I18n,
) => {
  const translations = {
    'liter-per-second': {
      short: t(i18n)`L/s`,
      long: t(i18n)`liter per second`,
    },
    'cubic-meter-per-second': {
      short: t(i18n)`m³/s`,
      long: t(i18n)`cubic meter per second`,
    },
    'cubic-meter-per-hour': {
      short: t(i18n)`m³/h`,
      long: t(i18n)`cubic meter per hour`,
    },
    meter: {
      short: t(i18n)`m`,
      long: t(i18n)`meter`,
    },
    centimeter: {
      short: t(i18n)`cm`,
      long: t(i18n)`centimeter`,
    },
    millimeter: {
      short: t(i18n)`mm`,
      long: t(i18n)`millimeter`,
    },
    liter: {
      short: t(i18n)`L`,
      long: t(i18n)`liter`,
    },
    'cubic-meter': {
      short: t(i18n)`m³`,
      long: t(i18n)`cubic meter`,
    },
    'millimeter-per-second': {
      short: t(i18n)`mm/s`,
      long: t(i18n)`millimeter per second`,
    },
    'centimeter-per-second': {
      short: t(i18n)`cm/s`,
      long: t(i18n)`centimeter per second`,
    },
    degree: {
      short: t(i18n)`°`,
      long: t(i18n)`degree`,
    },
    percent: {
      short: t(i18n)`%`,
      long: t(i18n)`percent`,
    },
    ampere: {
      short: t(i18n)`A`,
      long: t(i18n)`ampere`,
    },
    watt: {
      short: t(i18n)`W`,
      long: t(i18n)`watt`,
    },
    volt: {
      short: t(i18n)`V`,
      long: t(i18n)`volt`,
    },
    ph: {
      short: t(i18n)`pH`,
      long: t(i18n)`ph`,
    },
    millivolt: {
      short: t(i18n)`mV`,
      long: t(i18n)`millivolt`,
    },
    ntu: {
      short: t(i18n)`NTU`,
      long: t(i18n)`ntu`,
    },
    fnu: {
      short: t(i18n)`FNU`,
      long: t(i18n)`fnu`,
    },
    celsius: {
      short: t(i18n)`°C`,
      long: t(i18n)`celsius`,
    },
    fahrenheit: {
      short: t(i18n)`°F`,
      long: t(i18n)`fahrenheit`,
    },
    'millisiemens-per-centimeter': {
      short: t(i18n)`mS/cm`,
      long: t(i18n)`millisiemens per centimeter`,
    },
  };

  unitDisplay = unitDisplay !== 'long' ? 'short' : 'long';

  return translations[unit as keyof typeof translations]?.[unitDisplay] ?? unit;
};
