import { SvgIcon, SvgIconProps } from '@mui/material';

const DownloadIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <rect opacity={0.2} x=".5" y=".5" width="23" height="23" rx="3" ry="3" />
    <path d="m20.5,24H3.5c-1.93,0-3.5-1.57-3.5-3.5V3.5C0,1.57,1.57,0,3.5,0h17c1.93,0,3.5,1.57,3.5,3.5v17c0,1.93-1.57,3.5-3.5,3.5ZM3.5,2c-.83,0-1.5.67-1.5,1.5v17c0,.83.67,1.5,1.5,1.5h17c.83,0,1.5-.67,1.5-1.5V3.5c0-.83-.67-1.5-1.5-1.5H3.5Z" />
    <path d="m16.71,10.29c-.39-.39-1.02-.39-1.41,0l-2.29,2.29v-7.59c0-.55-.45-1-1-1s-1,.45-1,1v7.59l-2.29-2.29c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l4,4c.09.09.2.17.33.22.12.05.25.08.38.08s.26-.03.38-.08c.12-.05.23-.12.33-.22l4-4c.39-.39.39-1.02,0-1.41Z" />
    <path d="m18.5,20H5.5c-.55,0-1-.45-1-1s.45-1,1-1h13c.55,0,1,.45,1,1s-.45,1-1,1Z" />
  </SvgIcon>
);

export default DownloadIcon;
