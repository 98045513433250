import { gql } from 'src/__generated__';

const GET_MASTER_DEVICE_CALIBRATION = gql(`#graphql
query DeviceCalibration_MasterDeviceCalibration($deviceId: ID!) {
  device(input: { id: $deviceId }) {
    id
    frankieSensors {
      device {
        id
        profile {
          sensor
        }
        dataConfiguration {
          deviceId
          calibration {
            level
            positionFromAngle {
              K
              P
              R
            }
          }
        }
      }
    }
  }
}
`);

export default GET_MASTER_DEVICE_CALIBRATION;
