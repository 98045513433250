import { gql } from '@/__generated__';

const CREATE_DGA_CONFIGURATION = gql(`#graphql
  mutation CreateDgaConfiguration($input: CreateDgaConfigInput!) {
    createDgaConfiguration(input: $input) {
      id
      code
      rut
      password
      type
      frequency
      hour
      weekday
      day
      enabled
    }
  }
`);

export default CREATE_DGA_CONFIGURATION;
