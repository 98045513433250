import { t } from '@lingui/macro';

const getDeviceStatusFormat = (status?: string | null) => {
  const data = {
    active: {
      status: 'active',
      name: t`Activar`,
      statusName: t`Activo`,
      background: '#222b36',
    },
    maintenance: {
      status: 'maintenance',
      name: t`Mantenimiento`,
      statusName: t`En mantenimiento`,
      color: '#ffc107',
      background: '#2b281d',
      buttom: '#323241',
    },
    testing: {
      status: 'testing',
      name: t`Prueba`,
      statusName: t`En prueba`,
      color: '#ff6207',
      background: '#233442',
      buttom: '#23292c',
    },
  } as Record<string, Record<string, string>>;
  if (status && status in data) return data[status];
  else return {};
};

export default getDeviceStatusFormat;
