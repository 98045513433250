import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '@/__generated__/graphql';

interface Parameters {
  page: number;
  order: string;
  rowsPerPage: number;
  searchValue: string;
  typeValue: string;
  organizationId: number;
  organization: Organization | null;
  icnId: number;
  filter: string;
}

interface DGADevicesState {
  parameters: Parameters;
  createDGAConfigurationModal: {
    open: boolean;
    deviceId?: string;
  };
  editDGAConfigurationModal:
    | {
        open: true;
        deviceId: string;
      }
    | {
        open: false;
      };
  updateDGAScheduleModal:
    | {
        open: false;
      }
    | {
        open: true;
        deviceId: string;
      };
}

const initialState: DGADevicesState = {
  parameters: {
    page: 0,
    order: 'name',
    rowsPerPage: 5,
    searchValue: '',
    typeValue: 'all',
    organizationId: 0,
    organization: null,
    icnId: 0,
    filter: 'all',
  },
  createDGAConfigurationModal: {
    open: false,
  },
  editDGAConfigurationModal: {
    open: false,
  },
  updateDGAScheduleModal: {
    open: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'dga_devices_store',
  initialState,
  reducers: {
    setParameters(state: DGADevicesState, action: PayloadAction<Parameters>): void {
      state.parameters = action.payload;
    },
    setCreateDGAConfigurationModal(
      state: DGADevicesState,
      action: PayloadAction<DGADevicesState['createDGAConfigurationModal']>,
    ) {
      state.createDGAConfigurationModal = action.payload;
    },
    setEditDGAConfigurationModal(
      state: DGADevicesState,
      action: PayloadAction<DGADevicesState['editDGAConfigurationModal']>,
    ) {
      state.editDGAConfigurationModal = action.payload;
    },
    setUpdateDGAScheduleModal(
      state: DGADevicesState,
      action: PayloadAction<DGADevicesState['updateDGAScheduleModal']>,
    ) {
      state.updateDGAScheduleModal = action.payload;
    },
  },
});

export default reducer;
export const {
  setParameters,
  setEditDGAConfigurationModal,
  setCreateDGAConfigurationModal,
  setUpdateDGAScheduleModal,
} = actions;
