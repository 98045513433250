import type { Station } from '@/__generated__/graphql';
import { formatSourceColor } from '@/utils/devices';
import ModalTabs from '@components/ModalTabs';
import FiltersTab from '@features/deviceSettingsFilters/components/FiltersTab';
import FlowLimitsTab from '@features/deviceSettingsFlowLimits/components/FlowLimitsTab';
import RadarParametersTab from '@features/deviceSettingsRadarParameters/components/RadarParametersTab';
import RatingCurvesTab from '@features/deviceSettingsRatingCurves/components/RatingCurvesTab';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState, type FC } from 'react';
import CalibrationTab from '@features/deviceSettingsCalibration/components/CalibrationTab';
import MathModelingTab from '@features/deviceSettingsMathModeling';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import GET_ACCESS_DEVICE_DATA_CONFIGURATION from '@/graphql/querys/access/getAccessDeviceDataConfiguration';

interface Props {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  device: Station;
}

interface ConfirmationDialog {
  title: string;
  content: string;
  action: () => void;
  actionButtonText: string;
}

const DeviceConfigurationModal: FC<Props> = ({ device, open, onClose }) => {
  const { _ } = useLingui();
  const { data: access } = useQuery(GET_ACCESS_DEVICE_DATA_CONFIGURATION, {
    variables: {
      input: {
        deviceId: device.id,
      },
    },
  });
  const deviceFormat = formatSourceColor(device);
  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialog | null>(null);
  const [dirtyForm, setDirtyForm] = useState(false);

  // Prevent user from leaving the page if the form is dirty
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (dirtyForm) event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirtyForm]);

  const handleClose = () => {
    if (dirtyForm) {
      setConfirmationDialog({
        title: _(msg`¿Estás seguro que deseas salir?`),
        content: _(msg`Los cambios que hayas realizado no se guardarán.`),
        action: () => {
          onClose();
          setDirtyForm(false);
          setConfirmationDialog(null);
        },
        actionButtonText: _(msg`Salir sin guardar`),
      });
    } else {
      onClose();
    }
  };

  const handleChangeTab = (changeTabAction: () => void) => {
    if (dirtyForm) {
      setConfirmationDialog({
        title: _(msg`¿Estás seguro que deseas cambiar de pestaña?`),
        content: _(msg`Los cambios que hayas realizado no se guardarán.`),
        action: () => {
          changeTabAction();
          setDirtyForm(false);
          setConfirmationDialog(null);
        },
        actionButtonText: _(msg`Cambiar de pestaña`),
      });
    } else {
      changeTabAction();
    }
  };

  if (!device || !access) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <Dialog open={confirmationDialog !== null}>
        <DialogTitle>{confirmationDialog?.title || ''}</DialogTitle>
        <DialogContent dividers>{confirmationDialog?.content || ''}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirmationDialog(null)}>
            <Trans>Volver al formulario</Trans>
          </Button>
          <Button variant="contained" color="error" onClick={confirmationDialog?.action}>
            {confirmationDialog?.actionButtonText || ''}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogTitle
        sx={{
          backgroundColor: '#2a3542',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Box pl={2} flexGrow={1}>
            {device?.name}
            <Typography variant="subtitle2" color="text.secondary">
              ID: <span style={{ color: 'white' }}>{device?.id}</span>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle2" color="text.secondary">
            <Trans>Tipo</Trans>:{' '}
            <span style={{ color: deviceFormat?.color }}>{deviceFormat?.text}</span>
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            <Trans>ORG</Trans>: <span style={{ color: 'white' }}>{device?.organization_name}</span>
          </Typography>
        </Box>
      </DialogTitle>
      <ModalTabs
        changeTabMiddleware={handleChangeTab}
        panels={[
          ...(access?.authorization.authorizationDevice?.viewCalibration
            ? [
                {
                  label: _(msg`Calibración`),
                  component: (
                    <CalibrationTab
                      deviceId={device.id}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewFlowLimit
            ? [
                {
                  label: _(msg`Límites de Caudal`),
                  component: (
                    <FlowLimitsTab
                      device={device}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewFilter
            ? [
                {
                  label: _(msg`Filtros`),
                  component: (
                    <FiltersTab
                      device={device}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewRatingCurve
            ? [
                {
                  label: _(msg`Curvas de Aforo`),
                  component: (
                    <RatingCurvesTab
                      device={device}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewMathModeling
            ? [
                {
                  label: _(msg`Modelamientos Matemáticos`),
                  component: (
                    <MathModelingTab
                      deviceId={device.id}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
          ...(access?.authorization.authorizationDevice?.viewRadarParameter
            ? [
                {
                  label: _(msg`Parámetros Radar`),
                  component: (
                    <RadarParametersTab
                      device={device}
                      handleClose={handleClose}
                      setDirtyForm={setDirtyForm}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Dialog>
  );
};

export default DeviceConfigurationModal;
