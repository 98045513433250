import { gql } from '@apollo/client';

const UPDATE_MAX_FLOW_CONFIGURATION = gql`
  mutation UpdateMaxFlowConfiguration($deviceId: String!, $maxFlow: Float!) {
    UpdateMaxFlowConfiguration(deviceId: $deviceId, maxFlow: $maxFlow) {
      message
      success
    }
  }
`;

export default UPDATE_MAX_FLOW_CONFIGURATION;
