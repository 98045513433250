import { gql } from '@/__generated__';

const GET_DEVICE_SENSOR = gql(`#graphql
  query DeviceCalibration_GetDeviceSensor($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        sensor
      }
    }
  }
`);

export default GET_DEVICE_SENSOR;
