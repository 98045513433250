import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material';
import {
  DateCalendar,
  DateTimeField,
  DigitalClock,
  LocalizationProvider,
} from '@mui/x-date-pickers-latest';
import { AdapterDayjs } from '@mui/x-date-pickers-latest/AdapterDayjs';
import 'dayjs/locale/es';
import EventIcon from '@mui/icons-material/Event';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

const CustomDateTimePicker = ({
  label,
  selectedDate,
  handleDateChange,
  minDateTime,
  placement = 'right-end',
  ampm = false,
}: {
  label?: string;
  selectedDate: dayjs.Dayjs;
  minDateTime?: dayjs.Dayjs;
  handleDateChange: (newDate: dayjs.Dayjs) => void;
  placement?: PopperPlacementType;
  ampm?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const textFieldContainerRef = useRef();

  const [showCalendarPopper, setShowCalendarPopper] = useState(false);

  const handleShowCalendarPopper = () => {
    inputRef?.current?.focus();
    setShowCalendarPopper(true);
  };

  const handleDayMonthYearChange = (newDayMonthYear: dayjs.Dayjs) => {
    const newDate = newDayMonthYear.hour(selectedDate.hour()).minute(selectedDate.minute());
    handleDateChange(newDate);
  };

  const handleHourMinuteChange = (newHourMinute: dayjs.Dayjs) => {
    const newDate = newHourMinute
      .date(selectedDate.date())
      .month(selectedDate.month())
      .year(selectedDate.year());
    handleDateChange(newDate);
  };

  const isDateDisabled = (date: dayjs.Dayjs) => {
    if (!minDateTime) return false;
    return date.isBefore(minDateTime.add(1, 'hour').subtract(1, 'day'));
  };

  const shouldDisableTime = (time: dayjs.Dayjs) => {
    if (!minDateTime) return false;
    return time.isBefore(minDateTime.add(1, 'hour'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Box sx={{ display: 'flex' }}>
        <Box ref={textFieldContainerRef}>
          <DateTimeField
            inputRef={inputRef}
            label={label}
            onBlur={() => setShowCalendarPopper(false)}
            value={selectedDate}
            onFocus={() => setShowCalendarPopper(true)}
            minDateTime={minDateTime}
            onChange={(e) => {
              if (e != null) {
                handleDateChange(e);
              }
            }}
            ampm={ampm}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onMouseDown={(e) => e.preventDefault()}>
                  <IconButton edge="end" onClick={handleShowCalendarPopper}>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Popper
          placement={placement}
          open={showCalendarPopper}
          anchorEl={textFieldContainerRef.current}
          onMouseDown={(e) => e.preventDefault()}
        >
          <Paper sx={{ border: 2, mx: 2, borderColor: 'text.secondary' }}>
            <Box style={{ display: 'flex', width: 'fit-content' }}>
              <DateCalendar
                shouldDisableDate={isDateDisabled}
                onChange={handleDayMonthYearChange}
                value={selectedDate}
              />
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  align="center"
                  sx={{ mt: 2, pb: 1, borderBottom: 1, borderColor: 'text.secondary' }}
                >
                  Hora
                </Typography>
                <DigitalClock
                  shouldDisableTime={shouldDisableTime}
                  timeStep={60}
                  ampm={ampm}
                  sx={{ flex: 1, maxHeight: '240px' }}
                  value={selectedDate.isValid() ? selectedDate : null}
                  onChange={handleHourMinuteChange}
                />
              </Box>
            </Box>
          </Paper>
        </Popper>
      </Box>
    </LocalizationProvider>
  );
};
export default CustomDateTimePicker;
