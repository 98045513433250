import { gql } from '@apollo/client';

const UPDATE_DEVICE_DGA_INFORMATION = gql`
  mutation UpdateDeviceDGAInformation(
    $deviceId: String!
    $dgaCode: String!
    $dgaUser: String!
    $dgaPassword: String!
    $dgaExtractionType: String!
  ) {
    UpdateDeviceDGAInformation(
      deviceId: $deviceId
      dgaCode: $dgaCode
      dgaUser: $dgaUser
      dgaPassword: $dgaPassword
      dgaExtractionType: $dgaExtractionType
    ) {
      message
      success
    }
  }
`;

export default UPDATE_DEVICE_DGA_INFORMATION;
