import { gql } from '@apollo/client';

const DGA_DEVICE_CONFIGURATION = gql`
  query DGADeviceConfiguration($deviceId: String!) {
    DGADeviceConfiguration(deviceId: $deviceId) {
      maxFlow
    }
  }
`;

export default DGA_DEVICE_CONFIGURATION;
