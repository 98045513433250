import { gql } from '@apollo/client';

const GET_DEVICE_DGA_INFORMATION = gql`
  query GetDeviceDGAInformation($deviceId: String!) {
    GetDeviceDGAInformation(deviceId: $deviceId) {
      dga_code
      dga_password
      dga_user
      dga_extraction_type
    }
  }
`;

export default GET_DEVICE_DGA_INFORMATION;
