import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { createFragmentRegistry } from '@apollo/client/cache';
import { gql } from 'src/__generated__';
import {
  DEVICE_LIST_INFO_FRAGMENT,
  DEVICE_LIST_METRICS_FRAGMENT,
  DEVICE_LIST_MINICHARTS_FRAGMENT,
  DEVICE_LIST_PICTURE_FRAGMENT,
  DEVICE_LIST_DGA_INFO_FRAGMENT,
  DEVICE_LIST_DGA_CONFIGURATION_FRAGMENT,
  DEVICE_LIST_DGA_STATS_FRAGMENT,
  DEVICE_LIST_DGA_CHART_FRAGMENT,
} from 'src/features/devices2/graphql/queries/getDevices';

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_WS_URL,
    connectionParams: {
      authorization: window.localStorage.getItem('accessToken')
        ? `Bearer ${window.localStorage.getItem('accessToken')}`
        : '',
    },
  }),
);

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link: ApolloLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let authError = false;
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message.match(/you must be logged in/)) authError = true;
    });
    // TODO - this is a hack to force a logout when the token is expired
    // send to AuthGuard or something
    if (authError) {
      window.localStorage.removeItem('accessToken');
      window.location.href = '/';
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const removeTypenameLink = removeTypenameFromVariables();

export const client = new ApolloClient({
  link: from([removeTypenameLink, errorLink, link]),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(
      DEVICE_LIST_INFO_FRAGMENT,
      DEVICE_LIST_METRICS_FRAGMENT,
      DEVICE_LIST_MINICHARTS_FRAGMENT,
      DEVICE_LIST_PICTURE_FRAGMENT,
      DEVICE_LIST_DGA_INFO_FRAGMENT,
      DEVICE_LIST_DGA_CONFIGURATION_FRAGMENT,
      DEVICE_LIST_DGA_STATS_FRAGMENT,
      DEVICE_LIST_DGA_CHART_FRAGMENT,
    ),
    typePolicies: {
      Query: {
        fields: {
          // // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
          // device: {
          //   read(_, { args, toReference }) {
          //     return toReference({
          //       __typename: 'Device',
          //       id: args!.input.id,
          //     });
          //   },
          // },
          announcement: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Announcement',
                id: args!.id,
              });
            },
          },
          auditLogs: {
            keyArgs: ['input'],
            merge(existing, incoming, { readField }) {
              const items = existing ? { ...existing.items } : {};
              incoming.items.forEach((item) => {
                items[readField('id', item)] = item;
              });
              return {
                items,
                pageInfo: incoming.pageInfo,
                access: incoming.access,
              };
            },

            read(existing) {
              if (existing) {
                return {
                  items: Object.values(existing.items),
                  pageInfo: existing.pageInfo,
                };
              }
            },
          },
        },
      },
      AlertsNotifications: {
        merge: true,
        fields: {
          history: {
            keyArgs: ['input'],
          },
        },
      },
      DeviceDataConfiguration: {
        keyFields: ['deviceId'],
      },
      MetricsView: {
        keyFields: ['masterId'],
      },
      station: {
        keyFields: ['id'],
      },
      canalUser: {
        keyFields: ['id'],
      },
      notification: {
        merge: true,
      },
      UserPreferencesMetricsField: {
        merge: false,
      },
    },
  }),
  ssrMode: false,
  connectToDevTools: true,
});
