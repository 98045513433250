//

const ROLES_OPTIONS_COLOR = {
  1: '#00e393',
  2: '#03fcf4',
  3: '#ff7373',
  4: '#b70fff',
};

const getColorRoles = (id: number) => {
  return ROLES_OPTIONS_COLOR[id];
};

export default getColorRoles;
