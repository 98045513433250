import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { gql, useApolloClient } from '@apollo/client';
import { get, isNil } from 'lodash';
import Messages from './Messages';
import Alerts from './Alerts';
import { DeviceDialogProps } from '../../dialogInterfaces';

const DeviceNotifications = ({
  show,
  device,
  handleClose,
}: DeviceDialogProps) => {
  const client = useApolloClient();

  const [state, setState] = useState({
    toggleType: 'messages',
    messages: [],
  });

  const getMessages = async (componentId, offset) => {
    return get(
      await client.query({
        query: gql`
          query Messages($componentId: Int!, $offset: Int) {
            Messages(component_id: $componentId, offset: $offset) {
              contain
              component_id
              account_id
              modified
              created
              account {
                id
                name
                username
                lastnames
              }
            }
          }
        `,
        variables: {
          componentId,
          offset: offset,
        },
        fetchPolicy: 'no-cache',
      }),
      'data.Messages',
      null,
    );
  };

  const handleGetMessages = async (componentId, offset) => {
    const messages = await getMessages(componentId, offset);
    if (!isNil(messages) && Array.isArray(messages)) {
      setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, ...messages],
      }));
    }
  };

  useEffect(() => {
    const componentId = get(device, 'parent_component_id', null);
    if (!isNil(componentId)) {
      handleGetMessages(componentId, 0);
    }
  }, [device]);

  useEffect(() => {
    if (!show) {
      setState({
        messages: [],
        toggleType: 'messages',
      });
    }
  }, [show]);

  const handleAlignmentChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setState({ ...state, toggleType: newAlignment });
  };

  const handleCloseWithoutLoading = () => {
    handleClose(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleCloseWithoutLoading}
      maxWidth={false}
      fullWidth
    >
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h6">{get(device, 'name', '')}</Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseWithoutLoading}
            >
              Cerrar
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={state.toggleType}
              exclusive
              fullWidth
              size="small"
              onChange={handleAlignmentChange}
              sx={{
                mb: 1,
              }}
            >
              <ToggleButton value="messages">Mensajes</ToggleButton>
              <ToggleButton value="alerts">Alertas</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {state.toggleType === 'messages' && (
            <Grid item xs={12} p={5}>
              <Messages
                messages={state.messages}
                setMessages={(messages) => setState({ ...state, messages })}
              />
            </Grid>
          )}
          {state.toggleType === 'alerts' && (
            <Grid item xs={12} p={5}>
              <Alerts />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceNotifications;
