// client
import { gql } from 'src/__generated__';

const DEVICE_PROFILE = gql(`
	query GetDeviceProfile( $input: DeviceInput!) {
		device(input: $input) {
			id
			profile {
				id
				name
				type: type
				sensor
				source
				sourceInfo
				status
				organization {
					id
					name
				}
				project
				created
				dateAction
			}
			hardwareConfiguration {
				id
				account {
					id
					username
					name
					lastnames
				}
				images
				googleDriveUrl
				dateAction
				sensorLevelOption {
					id
					name
				}
				serialNumberLevel
				modbusIdLevel
				batchNumberLevelSensor
				sensorVelocityOption {
					id
					name
				}
				serialNumberVelocity
				modbusIdVelocity
				batchNumberVelocitySensor
				sensorFlowOption {
					id
					name
				}
				serialNumberFlow
				modbusIdFlow
				batchNumberFlowSensor
				energizationOption {
					id
					name
				}
				powerSupplyVoltageOption {
					id
					name
				}
				powerSupplyModel
				powerSupplySerialNumber
				powerSupplyBrand
				solarPanelsControllers
				numberSolarPanelsControllers
				quantityBattery
				modelBattery
				technologyOption {
					id
					name
				}
				batteryCapacityOption {
					id
					name
				}
				batteryVoltageOption {
					id
					name
				}
				quantitySun
				modelsSun
				watts
				numberSun
				cpuOption {
					id
					name
				}
				batchNumberCpu
				auxiliaryOption {
					id 
					name
				}
				numberAuxiliary
				batchNumberAuxiliary
				internetConnectionOption {
					id
					name
				}
				modelsConnectionOption {
					id
					name
				}
				imei
				approved
				mobileOperatorOption {
						id
						name
				}
				phoneNumber
				dataPlanMobileOption {
					id
					name
				}
				iccid
				antennaOption {
					id
					name
				}
				mac
				configurationIp
				administrator
				networkName
				observation
				lockNumber
				ip
				imageVersion
				idEmnify
				satellitalTypeOption { 
					id
					name
				}
				satellitalSv
				satellitalHv
				satellitalSerialName
				codeNumberWidgetlord
				serialNumberWidgetlord
				batchNumberWidgetlord
				batchNumberSimCard
				dataConnectionOption {
					id
					name
				}
			}
			deviceTypeMeasurement {
				level
				velocity
				flow
			}
			sensors {
				id
				type: type {
					id
					name
					label
				}
				configuration {
					sensorId
					batchNumber
					serialNumber
					modbusId
					sensorModelOption {
						id
						name
					}
				}
			}
		}
	}
`);

export default DEVICE_PROFILE;