import {
  Box,
  List,
  ListItem,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { type FC } from 'react';

interface Props {
  deviceId: string;
}

const DeviceProfileObservations: FC<Props> = ({ deviceId }) => {
  const observations = [
    {
      author: 'Shrimp and Chorizo Paella',
      initials: 'S',
      date: 'September 14, 2016',
      content:
        'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
    },
    {
      author: 'Shrimp and Chorizo Paella',
      initials: 'S',
      date: 'September 14, 2016',
      content:
        'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
    },
    {
      author: 'Shrimp and Chorizo Paella',
      initials: 'S',
      date: 'September 14, 2016',
      content:
        'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
    },
    {
      author: 'Shrimp and Chorizo Paella',
      initials: 'S',
      date: 'September 14, 2016',
      content:
        'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
    },
  ];

  return (
    <Box
      maxHeight={{
        sm: 'unset',
        md: 'calc(100vh - 320px)',
      }}
      minHeight={300}
      className="custom-scrollbar"
      sx={{
        overflowY: 'auto',
      }}
    >
      <List>
        {observations.map((item) => (
          <ListItem key={item.author}>
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[200] }} aria-label="recipe">
                    {item.initials}
                  </Avatar>
                }
                title={item.author}
                subheader={item.date}
              />
              <CardContent sx={{ pt: 0 }}>
                <Typography variant="body2" color="text.secondary">
                  {item.content}
                </Typography>
              </CardContent>
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DeviceProfileObservations;
