import { gql } from '@/__generated__';

const GET_DEVICE_OPTIONS_DATA = gql(`#graphql
  query DeviceOptions_GetDevice($input: DeviceInput!) {
    device(input: $input) {
      id
      profile {
        name
        type: type
        sensor
        source
        sourceInfo
        status
        componentID
        logicalDeviceID
        organization {
          id
          name
        }
        irrigation {
          id
          name
        }
      }
      alerts {
        lastFlow {
          min
          max
          overflow
          unit
          precision
        }
        lastLevel {
          min
          max
          overflow
          unit
          precision
        }
        distributionLimits {
          maxFlow
          unit
          precision
        }
      }
    }
  }
`);

export default GET_DEVICE_OPTIONS_DATA;
