// client
import { gql } from '@apollo/client';

const FETCH_KANBAN_LIST = gql`
  query FecthKanbanList {
    FecthKanbanList {
      id
      name
      created
      account {
        id
        name
        username
      }
    }
  }
`;

export default FETCH_KANBAN_LIST;
