import moment from 'moment';
import 'moment/locale/es';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import UPDATE_DGA_TICKET_INFORMATION_FOR_DEVICE from '@features/dga/graphql/mutations/updateDGATicketInformationForDevice';
import { get } from 'lodash';
import Loading from 'src/components/Loading';
import { DialogWrapper, FieldsContent, Confirmation } from 'src/features/DGAReports/components';
import { InputValues, InputValuesErrors } from 'src/features/DGAReports/types/';
import { useModalStatusMessage } from 'src/hooks';
import { useDispatch, useSelector } from 'src/store';
import { setUpdateDGATicketModal } from 'src/features/dga/slices/dgaSlice';

const defaultTicketValues: InputValues = {
  ticketDate: moment(),
  ticketID: '',
};

const defaultErrors: InputValuesErrors = {
  ticketID: {
    isOnError: false,
    message: null,
  },
};

const TicketConfiguration = () => {
  const dispatch = useDispatch();
  const { updateDGATicketModal } = useSelector((state) => state.dga_store);
  const show = updateDGATicketModal.open;
  const handleClose = () => {
    dispatch(
      setUpdateDGATicketModal({
        open: false,
      }),
    );
  };
  const deviceId = updateDGATicketModal.open && updateDGATicketModal.deviceId;

  moment.locale('es');

  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [values, setValues] = useState<InputValues>(defaultTicketValues);
  const [inputValueErrors, setInputValueErrors] = useState<InputValuesErrors>(defaultErrors);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [PutTicketConfiguration, { loading }] = useMutation(
    UPDATE_DGA_TICKET_INFORMATION_FOR_DEVICE,
  );

  const toastError = () => {
    openModalErrorMessage('Algo salió mal al intentar configurar el ticket DGA.');
  };

  const checkInputErrors = (inputValues: InputValues) => {
    const isError = inputValues.ticketID.length === 0;

    setInputValueErrors({
      ...inputValueErrors,
      ticketID: {
        isOnError: isError,
        message: isError ? 'El Ticket ID no puede estar vacío.' : null,
      },
    });
    return isError;
  };

  const handleConfirmCreation = () => {
    if (!deviceId) return;
    PutTicketConfiguration({
      variables: {
        deviceId: deviceId,
        ticketTimestamp: values.ticketDate.valueOf(),
        ticketID: values.ticketID,
      },
    })
      .then((response) => {
        const success = get(response, 'data.UpdateDGATicketInformationForDevice.success', false);
        if (success) {
          openModalSuccessMessage('Ticket DGA configurado con éxito!');
          handleInternalClose();
        } else {
          toastError();
        }
      })
      .catch((error) => {
        console.log(error);
        toastError();
      });
  };

  const handleCreate = () => {
    const didHaveErrors = checkInputErrors(values);
    if (didHaveErrors) return;
    setShowConfirmation(true);
  };

  const handleInternalClose = () => {
    setValues(defaultTicketValues);
    setInputValueErrors(defaultErrors);
    setShowConfirmation(false);
    handleClose();
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCloseWithoutLoading = () => {
    handleInternalClose();
  };

  if (loading) {
    return (
      <DialogWrapper open={show} onClose={handleCloseWithoutLoading}>
        <Loading />
      </DialogWrapper>
    );
  }

  if (showConfirmation) {
    return (
      <DialogWrapper open={show} onClose={handleCloseWithoutLoading}>
        <Confirmation
          ticketID={values.ticketID}
          ticketTimestamp={values.ticketDate}
          handleConfirmCreation={handleConfirmCreation}
          handleCloseConfirmation={handleCloseConfirmation}
        />
      </DialogWrapper>
    );
  }

  return (
    <DialogWrapper open={show} onClose={handleCloseWithoutLoading}>
      {loading ? (
        <Loading />
      ) : (
        <FieldsContent
          values={values}
          setValues={setValues}
          inputValueErrors={inputValueErrors}
          handleInternalClose={handleInternalClose}
          handleCreate={handleCreate}
        />
      )}
    </DialogWrapper>
  );
};

export default TicketConfiguration;
