import { gql } from '@apollo/client';

const RETRY_DGA_REPORTS_FOR_DEVICE = gql`
  mutation RetryDGAReportsForDevice($deviceId: String!) {
    RetryDGAReportsForDevice(deviceId: $deviceId) {
      message
      success
    }
  }
`;

export default RETRY_DGA_REPORTS_FOR_DEVICE;
