import type { AppBarProps } from '@mui/material';
import { AppBar, Box, Chip, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { experimentalStyled, useTheme } from '@mui/material/styles';
import { type FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountPopover from 'src/components/AccountPopover';
import LanguagePopover from 'src/components/LanguagePopover';
import LastDistributionData from 'src/components/LastDistributionData';
import NotificationsPopover from 'src/components/NotificationsPopover';
import OrganizationSetting from 'src/components/OrganizationSetting';
import { LogoAmaru } from 'src/components/logo/';
import DebtsAlertPopover from 'src/features/debtsAlert/components/DebtsAlertPopover';
import { AccountGeneral } from 'src/features/userProfile';
import useAuth from 'src/hooks/useAuth';
import MenuIcon from 'src/icons/Menu';
import { useSelector } from 'src/store';
import DistributionIrrigationSelect from './DistributionIrrigationSelect';
import NotificationsPopup from '@features/notifications/components/NotificationsPopup';
import UserMenu from '@features/userMenu';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  onSidebarPostOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    // backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = ({ onSidebarMobileOpen }) => {
  const { _ } = useLingui();
  const { user, isSimulating, stopUserSimulation } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();

  const { intervalId } = useSelector((state) => state.global_config);

  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const { pathname } = location;
    if (pathname !== '/device-configuration') {
      clearInterval(intervalId);
    }
  }, [location.pathname]);

  return (
    <DashboardNavbarRoot elevation={0}>
      <Box sx={{ display: 'inline-flex', minHeight: 64 }}>
        <Box
          onClick={() => navigate({ pathname: '/' })}
          sx={{
            width: 250,
            cursor: 'pointer',
            textAlign: 'center',
            height: 64,
            padding: '10px 0 8px 0',
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          <LogoAmaru />
          {/* <LogoCapta /> */}
        </Box>
        <Box sx={{ width: { xs: '100%', lg: 'calc(100% - 250px)' } }}>
          <Toolbar
            sx={{
              // display: 'inline',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box display="flex" alignItems="center">
                <IconButton
                  color="inherit"
                  onClick={onSidebarMobileOpen}
                  sx={{
                    display: {
                      lg: 'none',
                    },
                  }}
                  size="large"
                >
                  <MenuIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box display={['none', 'none', 'none', 'flex']} alignItems="center">
                <LastDistributionData />
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <DistributionIrrigationSelect />
                <DebtsAlertPopover />
                {isSimulating && (
                  <Chip
                    label={_(msg`Dejar de simular`)}
                    color="error"
                    onClick={() => {
                      if (!isSimulating) return;
                      stopUserSimulation();
                      navigate(0);
                    }}
                  />
                )}
                <LanguagePopover />
                {user?.organization_id === 2 && <OrganizationSetting />}
                {user?.organization_id === 2 && <NotificationsPopup />}
                <NotificationsPopover />
                <AccountPopover />
                {user?.organization_id === 2 && <UserMenu />}
              </Box>
            </Box>
            {!isXS && (
              <Box display={['flex', 'flex', 'flex', 'none']} alignItems="center">
                <LastDistributionData />
              </Box>
            )}
          </Toolbar>
        </Box>
      </Box>
      <AccountGeneral />
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
