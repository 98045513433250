import ChangeStatusDevice from 'src/features/changeStatusDevice/components/ChangeStatusDevice';
import { DialogEnum } from '../DialogEnum';
import UpdateStationComponent from 'src/features/stationUpdate/components/UpdateStation';
import { HistoryStatusDevice, GateMovementHistory, DeleteGate, UpdateAlarm } from '.';
import { Gate } from 'src/types/gates/gate';
import AccessEndAlarm from './accessEndAlarm';

interface Props {
  dialogToShow: DialogEnum;
  handleDialogClose: (refetch: boolean) => void;
  gate: Gate;
}

const GateDialogComponents = ({ dialogToShow, handleDialogClose, gate }: Props) => {
  const handleEditClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleChangeStatusClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleStatusHistoryClose = () => {
    handleDialogClose(false);
  };

  const handleUserListClose = () => {
    handleDialogClose(false);
  };

  const handleUpdateAlarmClose = () => {
    handleDialogClose(false);
  };

  const handleDeleteClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleMovementHistoryClose = () => {
    handleDialogClose(false);
  };

  return (
    <>
      <GateMovementHistory
        gate={gate}
        show={dialogToShow === DialogEnum.gateMovementHistory}
        handleClose={handleMovementHistoryClose}
      />
      <ChangeStatusDevice
        show={dialogToShow === DialogEnum.changeStatus}
        handleClose={handleChangeStatusClose}
        device={gate}
      />
      <UpdateStationComponent
        show={dialogToShow === DialogEnum.edit}
        handleClose={handleEditClose}
        device={gate}
      />
      <DeleteGate
        show={dialogToShow === DialogEnum.delete}
        gate={gate}
        handleClose={handleDeleteClose}
      />
      <HistoryStatusDevice
        show={dialogToShow === DialogEnum.statusHistory}
        handleClose={handleStatusHistoryClose}
        device={gate}
      />
      <AccessEndAlarm
        show={dialogToShow === DialogEnum.userList}
        handleClose={handleUserListClose}
        device={gate}
      />
      <UpdateAlarm
        show={dialogToShow === DialogEnum.updateAlarm}
        handleClose={handleUpdateAlarmClose}
        gate={gate}
      />
    </>
  );
};

export default GateDialogComponents;
