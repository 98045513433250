// react
import { useEffect, useState } from 'react';

// material-ui
import { Box, Grid, Typography, CircularProgress } from '@mui/material';

// graphql
import { gql, useApolloClient } from '@apollo/client';
import { useSelector } from 'src/store';

// utils
import moment from 'moment';
import { get, isNil } from 'lodash';

const Alerts = () => {
  const { selectedStation } = useSelector((state) => state.stations_store);
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const getAlerts = async () => {
    const componentId = get(selectedStation, 'parent_component_id', null);
    setLoading(true);
    if (!isNil(componentId)) {
      const newAlerts = get(
        await client.query({
          query: gql`
            query Events($componentId: Int!) {
              Events(component_id: $componentId) {
                id
                component_id
                detail
                measured
                created
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            componentId,
          },
        }),
        'data.Events',
        null,
      );
      if (!isNil(newAlerts)) {
        const parsedAlerts = newAlerts.map((alert) => {
          let detail = '';
          try {
            detail = get(alert, 'detail', '');
          } catch (error) {
            console.log(error);
          }
          return {
            ...alert,
            detail,
          };
        });
        setAlerts(parsedAlerts);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getAlerts();
  }, []);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h6">Cargando...</Typography>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        maxHeight: '60vh',
        p: 4,
        backgroundColor: '#171c24',
        borderRadius: '10px',
        overflowY: 'scroll',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#2ECCFA',
          borderRadius: '10px',
        },
        scrollbarWidth: 'none',
      }}
    >
      {alerts.map((message, i) => (
        <Grid item xs={12}>
          <Box
            display="inline-block"
            sx={{
              backgroundColor: '#292b30',
              mb: 2,
              p: 2,
              borderRadius: '10px',
            }}
          >
            <Typography>{message.detail}</Typography>
            <Typography
              variant="caption"
              sx={{
                mt: 1,
              }}
            >
              {!isNil(get(message, 'measured', null))
                ? moment(message.measured).format('DD/MM/yyyy hh:mm a')
                : ''}
            </Typography>
          </Box>
        </Grid>
      ))}
      <Grid
        ref={(el) => {
          if (el) {
            el.scrollIntoView({
              block: 'end',
            });
          }
        }}
      />
    </Grid>
  );
};

export default Alerts;
