import { gql } from '@/__generated__';

const GET_DEVICE_POSITION_FROM_ANGLE_CALIBRATION = gql(`#graphql
  query DeviceCalibration_GetPositionFromAngleCalibration($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        sensor
      }
      dataConfiguration {
        deviceId
        calibration {
          positionFromAngle {
            K
            R
            P
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_POSITION_FROM_ANGLE_CALIBRATION;
