import COLORS from '@features/controlCenter/constants/colors.json';
import { CustomSeriesRenderItemReturn } from 'echarts';
import EChartsOptionBuilder, { SerieType } from './EChartsOptionBuilder';
import { DeviceFlowAlert, DeviceLevelAlert, MetricUnit } from '@/__generated__/graphql';
import { type I18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { type Formatters } from '@/hooks/useFormatters';

export const flowAlertsSeries = ({
  flow,
  distributionMaxFlow,
  unit,
  precision,
  xAxisId,
  yAxisId,
  i18n,
  formatters,
}: {
  flow: DeviceFlowAlert[] | undefined | null;
  distributionMaxFlow: number | undefined | null;
  unit: MetricUnit;
  precision: number;
  xAxisId: string | undefined;
  yAxisId: string;
  i18n: I18n;
  formatters: Formatters;
}): Parameters<EChartsOptionBuilder['addSeries']>[0] => {
  const { formatDateTime, formatMetricValue } = formatters;
  const series: Parameters<EChartsOptionBuilder['addSeries']>[0] = [];

  if (flow != null) {
    const flowIntervals: [number, number, number | null, number | null, number | null][] = [];
    for (let i = 0; i < flow.length; i++) {
      const currentFlow = flow[i];
      const nextTimestamp = flow[i + 1]?.timestamp ?? Date.now();
      flowIntervals.push([
        currentFlow.timestamp,
        nextTimestamp,
        currentFlow.min ?? null,
        currentFlow.max ?? null,
        currentFlow.overflow ?? null,
      ]);
    }
    series.push({
      meta: {
        type: 'line',
        name: i18n._(msg`Alertas de caudal`),
        data: flowIntervals,
        dataType: 'flowAlerts',
        yAxisId,
        xAxisId,
      },
      value(serieValue) {
        return {
          ...serieValue,
          type: 'custom' as const,
          z: 1000,
          clip: true,
          encode: {
            x: [0, 1],
            y: [2, 3, 4],
            tooltip: [2, 3, 4],
          },
          dimensions: ['timestamp', 'timestamp2', 'min', 'max', 'overflow'],
          renderItem(_, api) {
            const a1 = api.coord([api.value(0), api.value(2)]);
            const a2 = api.coord([api.value(1), api.value(2)]);
            const b1 = api.coord([api.value(0), api.value(3)]);
            const b2 = api.coord([api.value(1), api.value(3)]);
            const c1 = api.coord([api.value(0), api.value(4)]);
            const c2 = api.coord([api.value(1), api.value(4)]);
            const group: CustomSeriesRenderItemReturn = {
              type: 'group',
              focus: 'self',
              children: [
                {
                  type: 'line',
                  shape: {
                    x1: a1[0],
                    x2: a2[0],
                    y1: a1[1],
                    y2: a2[1],
                  },
                  style: {
                    lineDash: 'dashed',
                    stroke: COLORS.BLUE,
                    lineWidth: 2,
                    opacity: 0.7,
                  },
                },
                {
                  type: 'line',
                  shape: {
                    x1: b1[0],
                    x2: b2[0],
                    y1: b1[1],
                    y2: b2[1],
                  },
                  style: {
                    lineDash: 'dashed',
                    stroke: COLORS.YELLOW,
                    lineWidth: 2,
                    opacity: 0.7,
                  },
                },
                {
                  type: 'line',
                  shape: {
                    x1: c1[0],
                    x2: c2[0],
                    y1: c1[1],
                    y2: c2[1],
                  },
                  style: {
                    lineDash: 'dashed',
                    stroke: COLORS.RED,
                    lineWidth: 2,
                    opacity: 0.7,
                  },
                },
              ],
            };
            return group;
          },
          tooltip: {
            show: true,
            trigger: 'item',
            formatter(params) {
              const [from, to, min, max, overflow] = params.value as number[];
              return `
${formatDateTime(new Date(from))} - ${formatDateTime(to)}
<br>${i18n._(msg`Caudal bajo`)}: <b>${formatMetricValue(min, { unit, precision })}</b>
<br>${i18n._(msg`Caudal alto`)}: <b>${formatMetricValue(max, { unit, precision })}</b>
<br>${i18n._(msg`Caudal de desborde`)}: <b>${formatMetricValue(overflow, { unit, precision })}</b>`;
            },
          },
        } as SerieType;
      },
    });
  }

  if (distributionMaxFlow != null)
    series.push({
      meta: {
        type: 'scatter' as const,
        name: i18n._(msg`Caudal máximo de reparto`),
        data: [[Date.now(), distributionMaxFlow]],
        dataType: 'distributionMaxFlow',
        yAxisId,
        color: COLORS.ORANGE,
      },
      value(serieValue) {
        return {
          ...serieValue,
          symbolSize: 0,
          markLine: {
            data: [
              {
                yAxis: distributionMaxFlow,
                lineStyle: {
                  color: COLORS.ORANGE,
                },
              },
            ],
            symbol: 'none',
            label: {
              show: false,
              position: 'middle',
              color: 'white',
              fontWeight: 'bold',
              formatter: (params) => formatMetricValue(params.value as number, { unit, precision }),
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        };
      },
    });
  return series;
};

export const levelAlertsSeries = ({
  level,
  ratingCurvesMin,
  ratingCurvesMax,
  unit,
  precision,
  yAxisId,
  xAxisId,
  i18n,
  formatters,
}: {
  level: DeviceLevelAlert[] | undefined | null;
  ratingCurvesMin: number | undefined | null;
  ratingCurvesMax: number | undefined | null;
  unit: MetricUnit;
  precision: number;
  yAxisId: string;
  xAxisId: string | undefined;
  i18n: I18n;
  formatters: Formatters;
}): Parameters<EChartsOptionBuilder['addSeries']>[0] => {
  const { formatDateTime, formatMetricValue } = formatters;
  const series: Parameters<EChartsOptionBuilder['addSeries']>[0] = [];
  if (level != null) {
    const levelIntervals: [number, number, number | null, number | null, number | null][] = [];
    for (let i = 0; i < level.length; i++) {
      const currentLevel = level[i];
      const nextTimestamp = level[i + 1]?.timestamp ?? Date.now();
      levelIntervals.push([
        currentLevel.timestamp,
        nextTimestamp,
        currentLevel.min ?? null,
        currentLevel.max ?? null,
        currentLevel.overflow ?? null,
      ]);
    }
    series.push({
      meta: {
        type: 'line',
        name: i18n._(msg`Alertas de nivel`),
        data: levelIntervals,
        dataType: 'levelAlerts',
        yAxisId,
        xAxisId,
      },
      value(serieValue) {
        return {
          ...serieValue,
          type: 'custom' as const,
          z: 1000,
          clip: true,
          encode: {
            x: [0, 1],
            y: [2, 3, 4],
            tooltip: [2, 3, 4],
          },
          dimensions: ['timestamp', 'timestamp2', 'min', 'max', 'overflow'],
          renderItem(_, api) {
            const a1 = api.coord([api.value(0), api.value(2)]);
            const a2 = api.coord([api.value(1), api.value(2)]);
            const b1 = api.coord([api.value(0), api.value(3)]);
            const b2 = api.coord([api.value(1), api.value(3)]);
            const c1 = api.coord([api.value(0), api.value(4)]);
            const c2 = api.coord([api.value(1), api.value(4)]);
            const group: CustomSeriesRenderItemReturn = {
              type: 'group',
              focus: 'self',
              children: [
                {
                  type: 'line',
                  shape: {
                    x1: a1[0],
                    x2: a2[0],
                    y1: a1[1],
                    y2: a2[1],
                  },
                  style: {
                    lineDash: 'dashed',
                    stroke: COLORS.BLUE,
                    lineWidth: 2,
                    opacity: 0.7,
                  },
                },
                {
                  type: 'line',
                  shape: {
                    x1: b1[0],
                    x2: b2[0],
                    y1: b1[1],
                    y2: b2[1],
                  },
                  style: {
                    lineDash: 'dashed',
                    stroke: COLORS.YELLOW,
                    lineWidth: 2,
                    opacity: 0.7,
                  },
                },
                {
                  type: 'line',
                  shape: {
                    x1: c1[0],
                    x2: c2[0],
                    y1: c1[1],
                    y2: c2[1],
                  },
                  style: {
                    lineDash: 'dashed',
                    stroke: COLORS.RED,
                    lineWidth: 2,
                    opacity: 0.7,
                  },
                },
              ],
            };
            return group;
          },
          tooltip: {
            show: true,
            trigger: 'item',
            formatter(params) {
              const [from, to, min, max, overflow] = params.value as number[];
              return `
${formatDateTime(new Date(from))} - ${formatDateTime(to)}
<br>${i18n._(msg`Nivel bajo`)}: <b>${formatMetricValue(min, { unit, precision })}</b>
<br>${i18n._(msg`Nivel alto`)}: <b>${formatMetricValue(max, { unit, precision })}</b>
<br>${i18n._(msg`Nivel de desborde`)}: <b>${formatMetricValue(overflow, { unit, precision })}</b>`;
            },
          },
        } as SerieType;
      },
    });
  }

  if (ratingCurvesMax != null || ratingCurvesMin != null)
    series.push({
      meta: {
        type: 'scatter' as const,
        name: i18n._(msg`Límites de curvas de aforo`),
        data: [
          ...(ratingCurvesMax != null ? [[Date.now(), ratingCurvesMax]] : []),
          ...(ratingCurvesMin != null ? [[Date.now(), ratingCurvesMin]] : []),
        ],
        dataType: 'ratingCurves',
        yAxisId,
      },
      value(serieValue) {
        return {
          ...serieValue,
          symbolSize: 0,
          markLine: {
            data: [
              ...(ratingCurvesMax != null
                ? [
                    {
                      yAxis: ratingCurvesMax,
                      name: i18n._(msg`Límite superior de curvas de aforo`),
                      lineStyle: {
                        color: COLORS.RED,
                      },
                    },
                  ]
                : []),
              ...(ratingCurvesMin != null
                ? [
                    {
                      yAxis: ratingCurvesMin,
                      name: i18n._(msg`Límite inferior de curvas de aforo`),
                      lineStyle: {
                        color: COLORS.YELLOW,
                      },
                    },
                  ]
                : []),
            ],
            symbol: 'none',
            label: {
              show: false,
              position: 'middle',
              color: 'white',
              fontWeight: 'bold',
              formatter: (params) =>
                `${params.seriesName}: ${formatMetricValue(params.value as number, {
                  unit,
                  precision,
                })}`,
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        };
      },
    });

  return series;
};

export const connectionStateMarkAreaSerie = ({
  serieName,
  connectionState,
  yAxisId,
  xAxisId,
  i18n,
}: {
  serieName: string;
  connectionState: [number, number][];
  yAxisId: string;
  xAxisId?: string;
  i18n: I18n;
}): Parameters<EChartsOptionBuilder['addSeries']>[0][number] => ({
  meta: {
    type: 'scatter',
    name: serieName,
    dataType: 'connectionState',
    yAxisId,
    xAxisId,
  },
  value(serieValue) {
    const connectionStateMarkAreaData = connectionState;
    const serieData = connectionStateMarkAreaData?.map(([timestamp, value]) => [
      timestamp,
      value === 1 ? i18n._(msg`conectado`) : i18n._(msg`desconectado`),
    ]) as [number, string][];
    return {
      ...serieValue,
      data: serieData,
      z: -1,
      markArea: {
        data: connectionStateMarkAreaData.reduce(
          (acc, current, index) => {
            if (index === connectionStateMarkAreaData.length - 1) {
              const lastIndex = connectionStateMarkAreaData.length - 1;
              if (connectionStateMarkAreaData[lastIndex][1] === 1) {
                acc.push([
                  {
                    xAxis: connectionStateMarkAreaData[lastIndex][0],
                  },
                  {
                    xAxis: Date.now(),
                  },
                ]);
              }
            } else if (current[1] === 1) {
              acc.push([
                {
                  xAxis: current[0],
                },
                {
                  xAxis: connectionStateMarkAreaData[index + 1][0],
                },
              ]);
            }
            return acc;
          },
          [] as NonNullable<echarts.MarkAreaComponentOption['data']>,
        ),
      },
    };
  },
});
