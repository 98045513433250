import type { ComponentType, FC, PropsWithChildren } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import RootErrorBoundary from './RootErrorBoundary';

interface Props extends PropsWithChildren {
  fallback?: ComponentType<FallbackProps>;
}

const ApolloErrorBoundary: FC<Props> = ({ children, fallback }) =>
  fallback ? (
    <ErrorBoundary FallbackComponent={fallback}>{children}</ErrorBoundary>
  ) : (
    <RootErrorBoundary>{children}</RootErrorBoundary>
  );

export default ApolloErrorBoundary;
