import { gql } from '@/__generated__';

const FETCH_ACCOUNT_STATIONS = gql(`#graphql
  query FetchAccountStations($input: DeviceInput!) {
    device(input: $input) {
      id
      users {
        id
        name
        username
        lastnames
        phone
        hasAccess
        alertsNotificationSettings {
           notifyEmail
           notifySms
           notifyWhatsapp
        }
      }
    }
  }
`);

export default FETCH_ACCOUNT_STATIONS;
