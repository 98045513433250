// client
import { gql } from '@apollo/client';

const FETCH_KANBAN_TASK = gql`
  query FecthKanbanTask {
    FecthKanbanTask {
      id
      name
      created
      created_index
      list_id
      device_id
      tags
      account {
        id
        name
        username
        picture
        name
        lastnames
        phone
        organization_id
      }
    }
  }
`;

export default FETCH_KANBAN_TASK;
