import { gql } from '@/__generated__';

const UPDATE_DGA_SCHEDULE = gql(`#graphql
  mutation UpdateDgaSchedule($input: ChangeDgaScheduleInput!) {
    updateDgaSchedule(input: $input) {
      id
      code
      rut
      password
      type
      frequency
      hour
      weekday
      day
      enabled
    }
  }
`);

export default UPDATE_DGA_SCHEDULE;
