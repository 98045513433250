import { gql } from '@apollo/client';

const GET_DGA_HISTORIC_REPORTS_EXCEL = gql`
  query GetDGAHistoricReportsExcel(
    $deviceId: String!
    $userEmail: String!
    $start: Float!
    $stop: Float!
  ) {
    GetDGAHistoricReportsExcel(
      deviceId: $deviceId
      userEmail: $userEmail
      start: $start
      stop: $stop
    ) {
      deviceId
      start
      stop
    }
  }
`;

export default GET_DGA_HISTORIC_REPORTS_EXCEL;
