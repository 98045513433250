import { useQuery } from '@apollo/client';
import { Box, Dialog, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ListItemsEvents from './ListItemsEvents';
import Loading from 'src/components/Loading';
import { DeviceDialogProps } from 'src/components/optionsMenu/dialogInterfaces';
import FETCH_KANBAN_TASK from 'src/graphql/querys/binnacle/fetchkanbanTasks';
import BinnacleValues from './BinnacleValues';
import DEVICE_PROFILE from 'src/graphql/querys/deviceProfile';

// TODO: refactor this component
const DeviceProfile = ({ device, show, handleClose }: DeviceDialogProps) => {
  const {
    loading: loadingBinnacle,
    data: deviceProfileResult,
    refetch: refetchBinnacle,
  } = useQuery(DEVICE_PROFILE, {
    variables: {
      input: {
        id: device?.id ?? null,
      },
    },
  });

  const {
    loading: loadingKanbanTask,
    data: kanbanTaskData,
    refetch: refetchKanbanTask,
  } = useQuery(FETCH_KANBAN_TASK, { fetchPolicy: 'no-cache' });

  const [kanbanTask, setKanbanTask] = useState(null);
  const [binnacleValues, setBinnacleValues] = useState(null);

  const getBinnacle = () => {
    const foundBinnacle = deviceProfileResult?.device;
    setBinnacleValues(foundBinnacle ?? null);
  };

  const getKanbanTask = () => {
    const kanbanTaskArray = kanbanTaskData?.FecthKanbanTask;
    if (kanbanTaskArray?.length > 0) {
      const foundKanbanTask = kanbanTaskArray.filter((t) => {
        const fetchedDeviceId = t?.device_id ?? null;
        const currentDeviceId = device?.id ?? null;
        return fetchedDeviceId && currentDeviceId && fetchedDeviceId === currentDeviceId;
      });
      setKanbanTask(foundKanbanTask ?? null);
    } else {
      setKanbanTask(null);
    }
  };

  useEffect(getBinnacle, [device, deviceProfileResult]);
  useEffect(getKanbanTask, [device, kanbanTaskData]);

  useEffect(() => {
    refetchBinnacle();
    refetchKanbanTask();
  }, [show]);

  const handleInternalClose = () => {
    handleClose(false);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={show} onClose={handleInternalClose}>
      <Box
        sx={{
          minHeight: '90vh',
          p: 3,
        }}
      >
        <Grid container>
          {loadingBinnacle ? (
            <Loading />
          ) : (
            <BinnacleValues binnacleValues={binnacleValues} device={device} show={show} />
          )}
          <ListItemsEvents
            events={null}
            kanbanTask={kanbanTask}
            loadingTask={loadingKanbanTask}
            loadingEvents={false}
            title="Equipo"
          />
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DeviceProfile;
