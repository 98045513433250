import { gql } from '@/__generated__';

const GET_DGA_REPORTS_FOR_TABLE = gql(`#graphql
  query DgaList_GetDGAReportsForTable(
    $deviceId: String!
    $lastEvaluatedKey: String
    $lastEvaluatedSortKey: String
    $limit: Int!
  ) {
    GetDGAReportsForTable(
      deviceId: $deviceId
      lastEvaluatedKey: $lastEvaluatedKey
      lastEvaluatedSortKey: $lastEvaluatedSortKey
      limit: $limit
    ) {
      items {
        DeviceID
        timestamp
        execution_timestamp
        success
        values {
          flow
          level
          accumulated_volume
        }
        error_message
        status_code
        status_description
        numero_comprobante
      }
      lastEvaluatedKey
      lastEvaluatedSortKey
    }
  }
`);

export const DGA_REPORTS_TABLE_GET_DEVICE = gql(`#graphql
  query DgaList_ReportsTableDevice($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        name
      }
      dgaConfiguration {
        id
        type
      }
    }
  }
`);

export default GET_DGA_REPORTS_FOR_TABLE;
