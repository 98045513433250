import { useSubscription } from '@apollo/client';
import _ from 'lodash';
import { useEffect } from 'react';
import KANBAN_TASK from 'src/graphql/subscriptions/kanbanTask';
import {
  setColumnsKanban,
  setKanbanLists,
  setComments,
} from 'src/slices/postsNews';
import { useDispatch, useSelector } from 'src/store';
import { useAuth } from '.';

const useKanban = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { comments, taskId, columnsKanban, kanbanLists } = useSelector(
    (state) => state.posts_news,
  );

  // subscriptions de movimiento en bitacora
  const { data } = useSubscription(KANBAN_TASK, {
    variables: {
      accountId: _.get(user, 'id', null),
      organizationId: _.get(user, 'organization_id', null),
    },
  });

  useEffect(() => {
    if (columnsKanban && columnsKanban.length > 0) {
      // crear nueva tarea
      if (_.get(data, 'KanbanTasks.args.type', null) === 'created_task') {
        const newTask = [
          { ..._.omit(_.get(data, 'KanbanTasks'), ['args']) },
          ...columnsKanban,
        ];
        dispatch(setColumnsKanban(newTask));
      }
      // actualizar tarea
      if (_.get(data, 'KanbanTasks.args.type', null) === 'updated_task') {
        let updatedTask = columnsKanban.find(
          (column: any) => column.id === _.get(data, 'KanbanTasks.id'),
        );
        const filterColumns = columnsKanban.filter(
          (column: any) => column.id !== _.get(data, 'KanbanTasks.id'),
        );

        if (_.get(data, 'KanbanTasks.name', null) !== null)
          updatedTask = {
            ...updatedTask,
            name: _.get(data, 'KanbanTasks.name'),
          };
        if (_.get(data, 'KanbanTasks.list_id', null) !== null)
          updatedTask = {
            ...updatedTask,
            list_id: _.get(data, 'KanbanTasks.list_id'),
          };
        if (_.get(data, 'KanbanTasks.tags', null) !== null)
          updatedTask = {
            ...updatedTask,
            tags:
              _.get(data, 'kanbanTask.tags') !== 'updated'
                ? _.get(data, 'KanbanTasks.tags')
                : null,
          };

        const res = [updatedTask, ...filterColumns];
        setTimeout(() => {
          dispatch(
            setColumnsKanban(
              res.sort((a, b) => (a.created < b.created ? 1 : -1)),
            ),
          );
          return null;
        }, 600);
        dispatch(
          setColumnsKanban(
            filterColumns.sort((a, b) => (a.created < b.created ? 1 : -1)),
          ),
        );
      }
      // movimiento de un fila hacia otra columna
      // movimiento de tarea hacia otra lista
      if (_.get(data, 'KanbanTasks.args.type', '') === 'card_h') {
        const res = columnsKanban.map((element: any) => {
          return {
            ...element,
            list_id:
              element.id ===
              parseInt(_.get(data, 'KanbanTasks.args.sourceId', 0), 10)
                ? _.get(data, 'KanbanTasks.args.destinationId', '')
                : element.list_id,
          };
        });
        dispatch(setColumnsKanban(res));
      }
      // movimiento de una fila hacia otra fila en la misma columna
      // movimiento de tareas de forma vertical, dentro de la misma lista
      if (_.get(data, 'KanbanTasks.args.type', '') === 'card_v') {
        const res = columnsKanban.map((element: any) => {
          return {
            ...element,
            created_index:
              element.id ===
              parseInt(_.get(data, 'KanbanTasks.args.sourceId', 0), 10)
                ? _.get(data, 'KanbanTasks.args.sourceCreated')
                : parseInt(
                    _.get(data, 'KanbanTasks.args.destinationId', 0),
                    10,
                  ) === element.id
                ? _.get(data, 'KanbanTasks.args.destinationCreated')
                : element.created_index,
          };
        });
        dispatch(
          setColumnsKanban(
            res.sort((a, b) => (a.created_index < b.created_index ? 1 : -1)),
          ),
        );
      }
      // movimiento de una columna hacia otra columna,
      // movimiento de lista horizontal
      if (_.get(data, 'KanbanTasks.args.type', '') === 'list_h') {
        const res = kanbanLists.map((element: any) => {
          return {
            ...element,
            created:
              element.id === _.get(data, 'KanbanTasks.args.sourceId', '')
                ? _.get(data, 'KanbanTasks.args.sourceCreated', '')
                : _.get(data, 'KanbanTasks.args.destinationId', '') ===
                  element.id
                ? _.get(data, 'KanbanTasks.args.destinationCreated')
                : element.created,
          };
        });
        dispatch(
          setKanbanLists(res.sort((a, b) => (a.created < b.created ? 1 : -1))),
        );
      }
      // al crear un comentario en una tarea
      if (_.get(data, 'KanbanTasks.args.type', '') === 'created_comment') {
        if (
          _.get(data, 'KanbanTasks.args.sourceId', '') === taskId.toString()
        ) {
          console.log('hola mundo');
        }
        const comment = {
          id: _.get(data, 'KanbanTasks.id'),
          account: _.get(data, 'KanbanTasks.account'),
          created: _.get(data, 'KanbanTasks.created'),
          body: _.get(data, 'KanbanTasks.name'),
        };
        const res = [...comments, comment];
        dispatch(
          setComments(res.sort((a, b) => (a.created < b.created ? 1 : -1))),
        );
      }
      // elimiar una tarea
      if (_.get(data, 'KanbanTasks.args.type', '') === 'delete_task') {
        dispatch(
          setColumnsKanban(
            columnsKanban.filter(
              (t) => t.id !== _.get(data, 'KanbanTasks.id', 0),
            ),
          ),
        );
      }
      // elimiar una lista
      if (_.get(data, 'KanbanTasks.args.type', '') === 'delete_list') {
        dispatch(
          setKanbanLists(
            kanbanLists.filter(
              (t) => t.id !== _.get(data, 'KanbanTasks.list_id', ''),
            ),
          ),
        );
      }
    }
  }, [data?.KanbanTasks]);
};

export default useKanban;
