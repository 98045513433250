import { gql } from '@apollo/client';

const GET_SHOW_DEVICE_IN_DGA_LIST_STATE = gql`
  query GetShowInDGAListStateForDevice($deviceId: String!) {
    GetShowInDGAListStateForDevice(deviceId: $deviceId) {
      showDeviceInDGAList
    }
  }
`;

export default GET_SHOW_DEVICE_IN_DGA_LIST_STATE;
