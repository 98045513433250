// react
import { useState } from 'react';

// material-ui
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

// graphql
import { gql, useApolloClient } from '@apollo/client';

// utils
import { get, isNil } from 'lodash';
import moment from 'moment';

// hooks
import useAuth from 'src/hooks/useAuth';

// redux
import { useDispatch, useSelector } from 'src/store';
import { setSnackbar } from 'src/slices/components';

const Messages = ({ messages, setMessages }) => {
  const client = useApolloClient();

  const dispatch = useDispatch();
  const { selectedStation } = useSelector((state) => state.stations_store);

  const { user } = useAuth();

  const [contain, setContain] = useState('');

  const handlePostMessage = async () => {
    const message = get(
      await client.mutate({
        mutation: gql`
          mutation PostMessage(
            $contain: String!
            $accountId: Int!
            $componentId: Int!
          ) {
            PostMessage(
              contain: $contain
              account_id: $accountId
              component_id: $componentId
            ) {
              contain
              component_id
              account_id
              modified
              created
              account {
                id
                name
                lastnames
                username
              }
            }
          }
        `,
        variables: {
          contain,
          accountId: user.id,
          componentId: selectedStation.parent_component_id,
        },
      }),
      'data.PostMessage',
      null,
    );
    if (!isNil(message)) {
      setMessages([...messages, message]);
      setContain('');
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Error al enviar el mensaje',
          severity: 'error',
        }),
      );
    }
  };

  return (
    <Grid container>
      <Grid
        container
        sx={{
          maxHeight: '60vh',
          p: 4,
          backgroundColor: '#171c24',
          borderRadius: '10px',
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#2ECCFA',
            borderRadius: '10px',
          },
          scrollbarWidth: 'none',
        }}
      >
        {messages.map((message, i) => (
          <Grid item xs={12}>
            <Box
              display="inline-block"
              sx={{
                backgroundColor: '#292b30',
                mb: 2,
                p: 2,
                borderRadius: '10px',
              }}
            >
              <Typography>{message.contain}</Typography>
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                }}
              >
                {!isNil(get(message, 'created', null))
                  ? moment(message.created).format('DD/MM/yyyy hh:mm a')
                  : ''}
              </Typography>
            </Box>
          </Grid>
        ))}
        <Grid
          ref={(el) => {
            if (el) {
              el.scrollIntoView({
                block: 'end',
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} mt={2} display="flex" flexDirection="row">
        <TextField
          fullWidth
          label="Escribe un mensaje..."
          sx={{
            backgroundColor: '#171c24',
            borderRadius: '10px',
          }}
          value={contain}
          onChange={(e) => setContain(e.target.value)}
        />
        <Tooltip title="Enviar mensaje">
          <Button
            onClick={handlePostMessage}
            sx={{
              color: 'inherit',
              ml: 2,
            }}
          >
            <SendIcon fontSize="large" />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Messages;
