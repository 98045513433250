import { gql } from '@/__generated__';

const GET_DEVICE_PROFILE_INFO = gql(`#graphql
  query DeviceProfileModal_GetDeviceProfileInfo($input: DeviceInput!) {
    device(input: $input) {
      id
      profile {
        name
        type
        created
        project
        organization {
          name
        }
        irrigation {
          name
        }
      }
      hardwareConfiguration {
        id
        images
      }
    }
  }
`);

export default GET_DEVICE_PROFILE_INFO;
