import { gql } from '@apollo/client';

const DGA_DEVICES = gql`
  query FetchDevicesForDGAReports(
    $organizationId: Int
    $limit: Int
    $offset: Int
    $search: String
    $type: String
    $filter: String
    $order: String
  ) {
    FetchDevicesForDGAReports(
      organizationId: $organizationId
      offset: $offset
      search: $search
      type: $type
      filter: $filter
      limit: $limit
      order: $order
    ) {
      count
      stations {
        name
        id
        node_id
        type: type
        organization_name
        status
        ticketDGA {
          ticketID
          ticketTimestamp
        }
        dga_obra_code
        dga_obra_password
        dga_obra_user
        dga_extraction_type
      }
    }
  }
`;

export default DGA_DEVICES;
