import { t } from '@lingui/macro';
import _ from 'lodash';

const colorBySensor = (type: string, sensor?: string | null): string => {
  if (type === 'CFC') return '#37b4b8';
  if (sensor?.includes('LF')) return '#e342f5';
  if (sensor?.includes('F')) return '#b388ff';
  if (sensor === 'LV') return '#42f5ec';
  if (sensor?.includes('L') || sensor?.includes('G')) return '#00c853';
  return '';
};

const formatText = (
  source: string | null | undefined,
  deviceType: string,
  deviceSourceInfo: string | null | undefined,
) => {
  if (!deviceSourceInfo) return deviceType;
  if (source === 'integration') return deviceType + ' ' + deviceSourceInfo;
  return deviceType;
};

const formatTextWithSourceInfo = (
  source: string | null | undefined,
  deviceType: string,
  deviceSourceInfo: string | null | undefined,
) => {
  const virtualText = t`Virtual`;
  const physicalText = t`Capta`;
  const cloneText = t`Clón`;
  const integrationText = t`Integración`;
  const deviceSource = source
    ? source
        .replace('virtual', virtualText)
        .replace('physical', physicalText)
        .replace('clone', cloneText)
        .replace('integration', integrationText)
    : '';

  if (deviceSourceInfo === 'REGIS' && source === 'virtual') return t`Regis`;
  if (!deviceSourceInfo) return deviceType;
  if (source === 'integration') return deviceType + ' ' + deviceSourceInfo;
  if (source === 'clone') return t`${deviceSource} del (${deviceSourceInfo})`;
  return deviceType;
};

const getExtraInfoSource = (
  source?: string | null,
  sourceInfo?: string | null,) => {
    if (source === 'clone') return t`Clón de ${sourceInfo}`;
    return ''
  }

const formatSourceColor = (station: any) => {
  const source = station?.source ?? null;
  const deviceSourceInfo = station?.source_info ?? null;
  const deviceType = station?.type ?? '';
  const sensor = station?.sensor ?? '';

  const newText = formatText(source, deviceType, deviceSourceInfo);
  const newTextWithSourceInfo = formatTextWithSourceInfo(source, deviceType, deviceSourceInfo);

  return {
    type: deviceType,
    text: newText,
    textAdvance: newTextWithSourceInfo,
    color: colorBySensor(deviceType, sensor),
  };
};

export const newFormatSourceColor = (
  device: {
    profile: {
      type: string;
      source?: string | null;
      sourceInfo?: string | null;
      sensor?: string | null;
    };
  }
) => {
  const { source, sourceInfo, type, sensor } = device.profile;

  return {
    text: formatText(source, type, sourceInfo),
    extraInfo: getExtraInfoSource(source, sourceInfo),
    color: colorBySensor(type, sensor),
  };
};

export default formatSourceColor;
