import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type StandardAutocompleteValue } from '@features/standardDesign';
import { msg } from '@lingui/macro';

export type PanelCheckbox = 'healthStatus';

type Irrigation = StandardAutocompleteValue<'irrigations'>;

export interface ControlCenterState {
  panelCheckboxes: Record<PanelCheckbox, { display: boolean; checked: boolean }>;
  selectedIrrigation: Irrigation | null;
  minichartsQueryPeriod: {
    label: ReturnType<typeof msg>;
    start: '-1h' | '-3h' | '-6h' | '-12h' | '-1d' | '-3d' | '-7d' | '-15d' | '-1mo';
    window: '5m' | '15m' | '30m' | '1h' | '3h' | '6h' | '12h' | '1d';
  };
}

const initialState: ControlCenterState = {
  panelCheckboxes: {
    healthStatus: { display: false, checked: false },
  },
  selectedIrrigation: null,
  minichartsQueryPeriod: { label: msg`1 día`, start: '-1d', window: '1h' },
};

const controlCenterSlice = createSlice({
  name: 'control_center_store',
  initialState,
  reducers: {
    togglePanelCheckbox(
      state,
      action: PayloadAction<{
        checkboxName: keyof typeof initialState.panelCheckboxes;
        checked?: boolean;
      }>,
    ) {
      const { checkboxName, checked } = action.payload;
      state.panelCheckboxes[checkboxName].checked =
        checked ?? !state.panelCheckboxes[checkboxName].checked;
    },
    setDisplayPanelCheckbox(
      state,
      action: PayloadAction<{
        checkboxName: keyof typeof initialState.panelCheckboxes;
        display: boolean;
      }>,
    ) {
      const { checkboxName, display } = action.payload;
      state.panelCheckboxes[checkboxName].display = display;
    },
    setSelectedIrrigation(state, action: PayloadAction<Irrigation | null>) {
      state.selectedIrrigation = action.payload;
    },
    setMinichartsQueryPeriod(
      state,
      action: PayloadAction<ControlCenterState['minichartsQueryPeriod']>,
    ) {
      state.minichartsQueryPeriod = action.payload;
    },
  },
});

const { actions, reducer } = controlCenterSlice;

export const {
  togglePanelCheckbox,
  setDisplayPanelCheckbox,
  setSelectedIrrigation,
  setMinichartsQueryPeriod: setMinichartQueryPeriod,
} = actions;
export default reducer;
