import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Edit } from '@mui/icons-material';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Box } from '@mui/material';
import { type FC } from 'react';
import { useFormatters } from '@/hooks/useFormatters';

interface Props {
  deviceId: string;
}

const DeviceProfileRecentActivity: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const { formatDateTime } = useFormatters();
  const auditLogs = [
    {
      id: '1',
      timestamp: 1631616000000,
      description: 'Se ha actualizado la configuración del dispositivo',
    },
    {
      id: '2',
      timestamp: 1631616000000,
      description: 'Se ha actualizado la configuración del dispositivo',
    },
    {
      id: '3',
      timestamp: 1631616000000,
      description: 'Se ha actualizado la configuración del dispositivo',
    },
    {
      id: '4',
      timestamp: 1631616000000,
      description: 'Se ha actualizado la configuración del dispositivo',
    },
    {
      id: '5',
      timestamp: 1631616000000,
      description: 'Se ha actualizado la configuración del dispositivo',
    },
  ];

  return (
    <Box
      maxHeight={{
        sm: 'unset',
        md: 'calc(100vh - 320px)',
      }}
      minHeight={300}
      className="custom-scrollbar"
      sx={{
        overflowY: 'auto',
      }}
    >
      <List>
        {auditLogs.length === 0 ? (
          <ListItem>
            <ListItemText secondary={_(msg`No hay actividad reciente`)} />
          </ListItem>
        ) : (
          auditLogs.map((log) => (
            <ListItem key={log?.id} sx={{ py: 0 }}>
              <ListItemAvatar>
                <Avatar sx={{ width: 34, height: 34, bgcolor: 'primary.main' }}>
                  <Edit fontSize="small" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={log.description} secondary={formatDateTime(log.timestamp)} />
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default DeviceProfileRecentActivity;
