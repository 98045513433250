import { ChangeDgaScheduleInput, DayOfWeek, DgaFrequency, DgaType } from '@/__generated__/graphql';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { z } from 'zod';

export const getDgaConfigurationValidationSchema = (i18n: I18n) =>
  z.object({
    deviceId: z.string().min(1, "El campo 'deviceId' no puede estar vacío"),
    code: z.string().min(1, "El campo 'code' no puede estar vacío"),
    rut: z
      .string()
      .regex(/^\d{6,8}-[\dK]$/, t(i18n)`El formato del RUT no es válido, ej: 12345678-K`),
    password: z.string().min(1, "El campo 'password' no puede estar vacío"),
    type: z.nativeEnum(DgaType),
  });

export const getDgaScheduleValidationSchema = (i18n: I18n) => {
  const baseSchema = z.object({
    deviceId: z.string().min(1, t(i18n)`El campo 'Dispositivo' no puede estar vacío`),
    frequency: z.nativeEnum(DgaFrequency),
    enable: z.literal(true),
  });

  const hoursValidation = z.coerce
    .number({ invalid_type_error: t(i18n)`Debes ingresar un número` })
    .int(t(i18n)`El campo 'Hora' debe ser un número entero`)
    .nonnegative()
    .min(0, t(i18n)`El campo 'Hora' debe estar entre 0 y 23`)
    .max(23, t(i18n)`El campo 'Hora' debe estar entre 0 y 23`);

  const dayValidation = z.coerce
    .number()
    .int(t(i18n)`El campo 'Día' debe ser un número entero`)
    .nonnegative()
    .min(1, t(i18n)`El campo 'Día' debe estar entre 1 y 27`)
    .max(27, t(i18n)`El campo 'Día' debe estar entre 1 y 27`);

  const dailySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Daily),
    hour: hoursValidation,
  });

  const weeklySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Weekly),
    hour: hoursValidation,
    weekday: z.nativeEnum(DayOfWeek),
  });

  const monthlySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Monthly),
    hour: hoursValidation,
    day: dayValidation,
  });

  const hourlySchema = baseSchema.extend({
    frequency: z.literal(DgaFrequency.Hourly),
  });

  const enabledSchema = z.discriminatedUnion('frequency', [
    dailySchema,
    weeklySchema,
    monthlySchema,
    hourlySchema,
  ]);

  const disabledSchema = baseSchema.extend({
    deviceId: z.string().min(1, t(i18n)`El campo 'Dispositivo' no puede estar vacío`),
    enable: z.literal(false),
  });

  return enabledSchema.or(disabledSchema) satisfies z.ZodType<ChangeDgaScheduleInput>;
};
