import { gql } from '@apollo/client';

const GET_DGA_STATISTICS = gql`
  query GetDgaStatistics($deviceId: String!) {
    GetDgaStatistics(deviceId: $deviceId) {
      yesterdaySuccessPercentage
      lastSevenDaysSuccessPercentage
      lastThirtyDaysSuccessPercentage
      percentageBelowMaxFlowInLastMonth
      lastThirtyDaysSuccessPercentageByday {
        timestamp
        success_percentage
      }
    }
  }
`;

export default GET_DGA_STATISTICS;
