import { useEffect, useState } from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import UPDATE_STATUS_DEVICE from 'src/features/changeStatusDevice/graphql/mutations/updateStatusDevice';
import FETCH_KANBAN_LIST from 'src/graphql/querys/binnacle/fetchKanbanList';
import CREATE_KANBAN_TASK from 'src/graphql/mutations/binnacle/createKanbaTask';
import { parse } from 'node-html-parser';
import { get } from 'lodash';
import { SelectCustom } from 'src/components/';
import { useModalStatusMessage, useUploadS3Images } from 'src/hooks/';
import {
  getStatusSelectAccount,
  getStatusSelectAdmin,
  getTextStatus,
} from 'src/features/changeStatusDevice/data/status';
import { DeviceDialogProps } from 'src/components/optionsMenu/dialogInterfaces';
import RichTextEditor from 'src/features/richTextEditor';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import GET_ACCESS_DEVICE_STATUS from 'src/graphql/querys/access/getAccessDeviceStatus';

const mapperStatusSelect = (status_select) =>
  status_select.map((r) => {
    return { value: r.status, label: r.name };
  });

const ChangeStatusDevice = ({ show, handleClose, device }: DeviceDialogProps) => {
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { uploadS3Images } = useUploadS3Images('amaru-news');
  const client = useApolloClient();
  const { data: access } = useQuery(GET_ACCESS_DEVICE_STATUS);
  const [paramForm, setParamForm] = useState({ status: '', body: '<p></p>' });
  const [loading, setLoading] = useState(false);
  const currentStatus = get(device, 'status', '');

  const getBody = async () => {
    const root = parse(paramForm.body);
    const value = await base64ToFilesString(root);

    return JSON.stringify(value.toString());
  };

  const createTask = async () => {
    const {
      data: { FecthKanbanList },
    } = await client.query({
      query: FETCH_KANBAN_LIST,
      fetchPolicy: 'no-cache',
    });
    if (FecthKanbanList && FecthKanbanList.length) {
      const TEXT_STATUS = getTextStatus();
      const textValue = TEXT_STATUS[paramForm.status];

      const newTask = {
        name: get(textValue, 'text'),
        list_id: FecthKanbanList[0].id,
        device_id: device.id,
        deviceName: get(device, 'name', ''),
        body: await getBody(),
        tags: JSON.stringify([
          {
            id: 10,
            name: get(textValue, 'name'),
            color: get(textValue, 'color'),
          },
        ]),
      };

      get(
        await client.mutate({
          mutation: CREATE_KANBAN_TASK,
          variables: newTask,
        }),
        'data',
        null,
      );
    }
  };

  const [UpdateStatus, { loading: loadingUpdated }] = useMutation(UPDATE_STATUS_DEVICE, {
    onError: () => {
      openModalErrorMessage('Error, vuelve a intentarlo.');
    },
    onCompleted: async (data) => {
      if (get(data, 'UpdateStatusDevice.success', null)) {
        // TODO: ivalderrama, eventualmente debería crear un evento y no ticket
        if (show) {
          await createTask();
        }
        openModalSuccessMessage('Actualizado con éxito!');
        handleInternalClose(true);
        setLoading(false);
      } else {
        openModalErrorMessage('Error, vuelve a intentarlo.');
      }
    },
  });

  const handleBody = (value) => {
    setParamForm({ ...paramForm, body: value });
  };

  const handleStatus = (value) => {
    setParamForm({ ...paramForm, status: value });
  };

  const handleInternalClose = (refetch) => {
    handleBody('<p></p>');
    handleClose(refetch);
  };

  const base64ToFilesString = async (root) => {
    await Promise.all(
      root?.querySelectorAll('img')?.map(async (element) => {
        const image = element.attributes.src;
        const files = [];
        try {
          const res = await fetch(image);
          const blob = await res.blob();
          files.push(new File([blob], 'image.jpeg', { type: blob.type }));
          const imgUrl = await uploadS3Images(files[0]);
          element.rawAttrs = `src="${imgUrl}"`;
        } catch (error) {
          console.log('err', error);
        }
        return element;
      }),
    );
    return root;
  };

  const generateVariables = async () => {
    return {
      deviceId: device.id,
      status: paramForm.status,
      description: await getBody(),
      parentComponentId: device.parent_component_id,
    };
  };

  const handleConfirmation = async () => {
    if (!show) return;
    setLoading(true);

    const variables = await generateVariables();

    try {
      UpdateStatus({ variables });
    } catch (error) {
      setLoading(false);
      openModalErrorMessage('Error, vuelve a intentarlo!');
    }
  };

  useEffect(() => {
    setParamForm({ ...paramForm, status: currentStatus, body: '<p></p>' });
  }, [device]);

  const handleCloseWithoutLoading = () => {
    handleInternalClose(false);
  };

  const STATUS_SELECT_ADMIN = getStatusSelectAdmin();
  const STATUS_SELECT_ACCOUNT = getStatusSelectAccount();

  return (
    <Dialog open={show} onClose={() => handleInternalClose(false)}>
      <DialogTitle>
        <Trans>Cambio de estado del dispositivo</Trans> - {get(device, 'name', '')}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} mt={2}>
            <SelectCustom
              name={_(msg`Estado de dispositivo`)}
              id="type-status-select"
              value={paramForm.status}
              options={mapperStatusSelect(
                access?.authorization.authorizationGeneral?.viewAllDeviceStatus ? STATUS_SELECT_ADMIN : STATUS_SELECT_ACCOUNT,
              )}
              onChange={handleStatus}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <RichTextEditor
              value={paramForm.body}
              onChange={handleBody}
              toolbarOptions={{
                selectHeading: true,
                buttonBold: true,
                buttonTaskList: true,
                buttonBulletList: true,
                buttonOrderedList: true,
              }}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button variant="outlined" color="info" onClick={handleCloseWithoutLoading}>
              <Trans>Cancelar</Trans>
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleConfirmation}
              disabled={loading || currentStatus === paramForm.status}
              sx={{
                ml: 2,
              }}
            >
              <Trans>Guardar</Trans>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeStatusDevice;
