import { AlertTypes } from 'src/__generated__/graphql';
import * as yup from 'yup';

const formSchema = yup
  .object()
  .shape({
    alertType: yup.string().oneOf(Object.values(AlertTypes)).required(),
    flowMin: yup.number().when('alertType', {
      is: AlertTypes.Flow,
      then: (schema) =>
        schema.required('Este campo es requerido').min(0, 'El valor no puede ser negativo'),
      otherwise: (schema) => schema.notRequired(),
    }),
    flowMax: yup.number().when('alertType', {
      is: AlertTypes.Flow,
      then: (schema) =>
        schema
          .required('Este campo es requerido')
          .min(0, 'El valor no puede ser negativo')
          .test('is-greater', 'El caudal máximo debe ser mayor al mínimo', function (value) {
            return value > this.parent.flowMin;
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    flowOverflow: yup.number().when('alertType', {
      is: AlertTypes.Flow,
      then: (schema) =>
        schema
          .required('Este campo es requerido')
          .min(0, 'El valor no puede ser negativo')
          .test('is-greater', 'El caudal de desborde debe ser mayor al máximo', function (value) {
            return value > this.parent.flowMax;
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    levelMin: yup.number().when('alertType', {
      is: AlertTypes.Level,
      then: (schema) =>
        schema.required('Este campo es requerido').min(0, 'El valor no puede ser negativo'),
      otherwise: (schema) => schema.notRequired(),
    }),
    levelMax: yup.number().when('alertType', {
      is: AlertTypes.Level,
      then: (schema) =>
        schema
          .required('Este campo es requerido')
          .min(0, 'El valor no puede ser negativo')
          .test('is-greater', 'El nivel máximo debe ser mayor que la mínima', function (value) {
            return value > this.parent.levelMin;
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    levelOverflow: yup.number().when('alertType', {
      is: AlertTypes.Level,
      then: (schema) =>
        schema
          .required('Este campo es requerido')
          .min(0, 'El valor no puede ser negativo')
          .test(
            'is-greater',
            'El nivel de desborde debe ser mayor que la máxima',
            function (value) {
              return value > this.parent.levelMax;
            },
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
  })
  .required();

type IFormInput = yup.InferType<typeof formSchema>;

export type { IFormInput };
export { formSchema };
