import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';
import { Cached } from '@mui/icons-material';
import TimeElapsed from '@components/TimeElapsed';
import { DevicesAnalysisDevice } from './DevicesAnalysis';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useFormatters } from '@/hooks/useFormatters';

interface Props {
  refetch: () => void;
  isPending: boolean;
  device: DevicesAnalysisDevice;
}

const getMainField = ({ type, hasFlow }: { type: string; hasFlow: boolean }) => {
  if (type === 'CFC')
    return {
      fieldName: 'position',
    } as const;

  if (hasFlow) {
    return {
      fieldName: 'flow',
    } as const;
  }

  return {
    fieldName: 'level',
  } as const;
};

const LastData: FC<Props> = (props) => {
  const { formatMetricValue } = useFormatters();
  const { _ } = useLingui();
  const { fieldName } = getMainField({
    type: props.device.profile.type,
    hasFlow: props.device.metrics.flow.lastData != null,
  });

  const { lastData, unit, precision } = props.device.metrics[fieldName] ?? null;
  const lastDataValue = lastData
    ? formatMetricValue(lastData?.[1], {
        unit,
        precision,
      })
    : null;

  const preciseLastDataValue = lastData
    ? formatMetricValue(lastData?.[1], {
        unit,
        precision: 7,
      })
    : null;

  if (!lastData)
    return (
      <Typography fontSize={20} textAlign="center" fontWeight="bold" color="text.secondary">
        <Trans>
          No hay datos
        </Trans>
      </Typography>
    );
  return (
    <>
      <Tooltip title={preciseLastDataValue}>
        <Box flexGrow={1}>
          <Typography variant="body2" color="text.secondary">
            <Trans comment="Análisis de estaciones - texto en widget de último dato">
              Último dato
            </Trans>
          </Typography>
          <Typography
            fontSize={20}
            fontWeight="bold"
            sx={{
              // finetuning fonts
              lineHeight: 1,
              mt: 0.5,
              mb: 0.1,
            }}
          >
            {props.isPending ? <Skeleton width="70%" /> : lastDataValue}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.isPending ? <Skeleton width="50%" /> : <TimeElapsed timestamp={lastData[0]} />}
          </Typography>
        </Box>
      </Tooltip>
      <Box flexGrow={0} display="grid" pr={1} sx={{ placeItems: 'center' }}>
        <Tooltip
          title={_(
            msg({
              comment: 'Análisis de estaciones - tooltip de botón para actualizar datos',
              message: 'Actualizar datos',
            }),
          )}
        >
          <IconButton onClick={props.refetch}>
            <Cached />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default LastData;
