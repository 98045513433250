import { gql } from '@apollo/client';

const GET_STATIONS_SINGLER = gql`
  query GetStationSingler($type: String) {
    getStationSingler(type: $type) {
      stations {
        id
        name
        type: type
        type_version
        serie
        housing
        sensor
        sensor_version
        parent_component_id
        logical_device_id
        node_id
        organization_id
        organization_name
        icn_id
        icn_name
        source
        source_info
        status
        isDryCanal
        distribution_max_flow
        show_in_dga_list
        dga_extraction_type
      }
    }
  }
`;

export default GET_STATIONS_SINGLER;
