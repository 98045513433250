import { gql } from '@apollo/client';

const GET_DGA_REPORTS_FOR_TABLE = gql`
  query GetDGAReportsForTable(
    $deviceId: String!
    $lastEvaluatedKey: String
    $lastEvaluatedSortKey: String
    $limit: Int!
  ) {
    GetDGAReportsForTable(
      deviceId: $deviceId
      lastEvaluatedKey: $lastEvaluatedKey
      lastEvaluatedSortKey: $lastEvaluatedSortKey
      limit: $limit
    ) {
      items {
        DeviceID
        timestamp
        execution_timestamp
        success
        values {
          flow
          level
          accumulated_volume
        }
        error_message
        status_code
        status_description
        numero_comprobante
      }
      lastEvaluatedKey
      lastEvaluatedSortKey
    }
  }
`;

export default GET_DGA_REPORTS_FOR_TABLE;
