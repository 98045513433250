import { gql } from '@apollo/client';

const FETH_LAST_ALERTS = gql`
  query FetchLastAlerts {
    FetchLastAlerts {
      id
      component_id
      irrigation_channel_network_id
      event_id
      detail
      measured
      event_params
      impact
      created
      modified
      component {
        id
        name
        node_id
      }
    }
  }
`;

export default FETH_LAST_ALERTS;
