import { Box, Divider, Grid, CircularProgress, Typography } from '@mui/material';
import _ from 'lodash';
import useAuth from '@/hooks/useAuth';
import { useFormatters } from '@/hooks/useFormatters';
// TODO: ivalderrama, deprecado
const ListItemsEvents = ({ events, kanbanTask, loadingTask, title, loadingEvents }) => {
  const { formatRelativeTime } = useFormatters();
  const { user } = useAuth();
  const getDateFormat = (dateString: string) => {
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const mm = dateParts[1];
    const dd = dateParts[2];
    const hh = dateParts[3];
    const nn = dateParts[4];
    const ss = dateParts[5];
    const date = new Date(`${year}-${mm}-${dd}T${hh}:${nn}:${ss}`);
    return formatRelativeTime(date);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={8}
        mt={_.get(user, 'role.id', 0) === 1 ? 5 : 12}
        sx={{
          paddingRight: 2,
        }}
      >
        {loadingEvents ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              p={2}
              sx={{
                minHeight: '45vh',
                borderRadius: '5px',
              }}
            >
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={5}
              >
                <CircularProgress />
              </Grid>
            </Box>
          </>
        ) : (
          <>
            {events && events.length > 0 && (
              <>
                <Divider />
                <Typography color="textPrimary" variant="h6">
                  Eventos del {title}
                </Typography>
                <Box
                  sx={{
                    maxHeight: '65vh',
                    overflowY: 'scroll',
                    '::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: '#ddd',
                      borderRadius: '10px',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  {events.map((event: any, idx: number) => {
                    return (
                      <Box
                        key={`${event.deviceId}/${event.creationDate}-${idx}`}
                        sx={{
                          border: '1px solid #dddddd43',
                          borderRadius: '5px',
                          padding: '20px',
                          marginTop: '10px',
                        }}
                      >
                        <Typography color="textSecondary">
                          {_.get(event, 'description', '')}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <Typography fontSize={12} color="textSecondary">
                            {getDateFormat(_.get(event, 'creationDate'))}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </>
        )}
      </Grid>
      {_.get(user, 'role.id', '') === 1 && (
        <Grid
          item
          xs={12}
          md={!events || events.length === 0 ? 12 : 4}
          mt={5}
          px={2}
          sx={{
            backgroundColor: 'background.default',
            borderRadius: '5px',
          }}
        >
          {loadingTask ? (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                p={2}
                sx={{
                  height: '40vh',
                  borderRadius: '5px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={5}
                >
                  <CircularProgress />
                </Grid>
              </Box>
            </>
          ) : (
            <>
              {kanbanTask && kanbanTask.length > 0 && (
                <>
                  <Divider />
                  <Typography color="textPrimary" variant="h6">
                    Tareas del {title}
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: '60vh',
                      overflowY: 'scroll',
                      '::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#ddd',
                        borderRadius: '10px',
                      },
                      scrollbarWidth: 'none',
                    }}
                  >
                    {kanbanTask?.map((task: any) => {
                      return (
                        <Box
                          sx={{
                            border: '1px solid #dddddd43',
                            borderRadius: '5px',
                            padding: '20px',
                            marginTop: '10px',
                          }}
                        >
                          <Typography color="textSecondary">{_.get(task, 'name', '')}</Typography>
                          <Typography color="textSecondary">
                            {_.get(task, 'account.name', '')}
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography fontSize={12} color="textSecondary">
                              {task?.created != null ? formatRelativeTime(task.created) : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </>
              )}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default ListItemsEvents;
