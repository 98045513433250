import { MetricUnit } from '@/__generated__/graphql';
import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export type FlowUnit =
  | MetricUnit.LiterPerSecond
  | MetricUnit.CubicMeterPerSecond
  | MetricUnit.CubicMeterPerHour;
export type DistanceUnit = MetricUnit.Meter | MetricUnit.Centimeter | MetricUnit.Millimeter;
export type VelocityUnit = MetricUnit.MillimeterPerSecond | MetricUnit.CentimeterPerSecond;
export type TemperatureUnit = MetricUnit.Celsius | MetricUnit.Fahrenheit;
export type AngleUnit = MetricUnit.Degree | MetricUnit.Fahrenheit;
export type VolumeUnit = MetricUnit.Liter | MetricUnit.CubicMeter;
export type ElectricPotentialUnit = MetricUnit.Volt | MetricUnit.Millivolt;
export type ElectricCurrentUnit = MetricUnit.Ampere;
export type ElectricPowerUnit = MetricUnit.Watt;
export type PhUnit = MetricUnit.Ph;
export type ConductivityUnit = MetricUnit.MillisiemensPerCentimeter;
export type TurbidityUnit = MetricUnit.Ntu | MetricUnit.Fnu;
export type PercentUnit = MetricUnit.Percent;

export const getUnits = (i18n: I18n) => {
  const units: Record<
    Exclude<MetricUnit, MetricUnit.None>,
    { value: string; short: string; long: string }
  > = {
    [MetricUnit.LiterPerSecond]: {
      value: 'liter-per-second',
      short: t(i18n)`L/s`,
      long: t(i18n)`liter per second`,
    },
    [MetricUnit.CubicMeterPerSecond]: {
      value: 'cubic-meter-per-second',
      short: t(i18n)`m³/s`,
      long: t(i18n)`cubic meter per second`,
    },
    [MetricUnit.CubicMeterPerHour]: {
      value: 'cubic-meter-per-hour',
      short: t(i18n)`m³/h`,
      long: t(i18n)`cubic meter per hour`,
    },
    [MetricUnit.Meter]: {
      value: 'meter',
      short: t(i18n)`m`,
      long: t(i18n)`meter`,
    },
    [MetricUnit.Centimeter]: {
      value: 'centimeter',
      short: t(i18n)`cm`,
      long: t(i18n)`centimeter`,
    },
    [MetricUnit.Millimeter]: {
      value: 'millimeter',
      short: t(i18n)`mm`,
      long: t(i18n)`millimeter`,
    },
    [MetricUnit.Liter]: {
      value: 'liter',
      short: t(i18n)`L`,
      long: t(i18n)`liter`,
    },
    [MetricUnit.CubicMeter]: {
      value: 'cubic-meter',
      short: t(i18n)`m³`,
      long: t(i18n)`cubic meter`,
    },
    [MetricUnit.MillimeterPerSecond]: {
      value: 'millimeter-per-second',
      short: t(i18n)`mm/s`,
      long: t(i18n)`millimeter per second`,
    },
    [MetricUnit.CentimeterPerSecond]: {
      value: 'centimeter-per-second',
      short: t(i18n)`cm/s`,
      long: t(i18n)`centimeter per second`,
    },
    [MetricUnit.Degree]: {
      value: 'degree',
      short: t(i18n)`°`,
      long: t(i18n)`degree`,
    },
    [MetricUnit.Percent]: {
      value: 'percent',
      short: t(i18n)`%`,
      long: t(i18n)`percent`,
    },
    [MetricUnit.Ampere]: {
      value: 'ampere',
      short: t(i18n)`A`,
      long: t(i18n)`ampere`,
    },
    [MetricUnit.Watt]: {
      value: 'watt',
      short: t(i18n)`W`,
      long: t(i18n)`watt`,
    },
    [MetricUnit.Volt]: {
      value: 'volt',
      short: t(i18n)`V`,
      long: t(i18n)`volt`,
    },
    [MetricUnit.Ph]: {
      value: 'ph',
      short: t(i18n)`pH`,
      long: t(i18n)`ph`,
    },
    [MetricUnit.Millivolt]: {
      value: 'millivolt',
      short: t(i18n)`mV`,
      long: t(i18n)`millivolt`,
    },
    [MetricUnit.Ntu]: {
      value: 'ntu',
      short: t(i18n)`NTU`,
      long: t(i18n)`ntu`,
    },
    [MetricUnit.Fnu]: {
      value: 'fnu',
      short: t(i18n)`FNU`,
      long: t(i18n)`fnu`,
    },
    [MetricUnit.Celsius]: {
      value: 'celsius',
      short: t(i18n)`°C`,
      long: t(i18n)`celsius`,
    },
    [MetricUnit.Fahrenheit]: {
      value: 'fahrenheit',
      short: t(i18n)`°F`,
      long: t(i18n)`fahrenheit`,
    },
    [MetricUnit.MillisiemensPerCentimeter]: {
      value: 'millisiemens-per-centimeter',
      short: t(i18n)`mS/cm`,
      long: t(i18n)`millisiemens per centimeter`,
    },
    [MetricUnit.Kilogram]: {
      value: 'kilogram',
      short: t(i18n)`kg`,
      long: t(i18n)`kilogram`,
    },
    [MetricUnit.MilligramPerLiter]: {
      value: 'milligram-per-liter',
      short: t(i18n)`mg/L`,
      long: t(i18n)`milligram per liter`,
    },
  };

  return units;
};

export const getUnit = (unit: MetricUnit, i18n: I18n) => {
  if (unit === MetricUnit.None) return null;
  const units = getUnits(i18n);
  return units[unit];
};
