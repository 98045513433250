import { Typography, Grid, Button, Divider } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ArrowRightIcon from 'src/icons/ArrowRight';
import { formatSourceColor } from 'src/utils/devices';

const BinnacleValues = ({ device, binnacleValues, show }) => {
  const [formatDevice, setFormatDevice] = useState(null);

  // TODO: ivalderrama, exigir elementos minimos para formatSourceColor, no funciona en modulo soporte
  useEffect(() => {
    if (show && device?.id) {
      setFormatDevice(formatSourceColor(device));
    }
  }, [show, device]);

  const renderMetrics = (sensor, title) => {
    return (
      <Box>
        <Typography>{title}</Typography>
        <Typography key={sensor} color="textSecondary" sx={{ fontSize: '14' }}>
          - {sensor}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid item md={6} xs={12} display="flex">
        {(binnacleValues?.hardwareConfiguration?.images || '') && (
          <Box
            sx={{
              width: '200px',
            }}
          >
            <img
              src={binnacleValues?.hardwareConfiguration?.images}
              alt={device?.name ?? ''}
              style={{
                width: '100%',
                border: '4px solid #3165786b',
                borderRadius: '4px',
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '20px',
          }}
        >
          <Typography color="textPrimary" variant="h5">
            {device?.name ?? ''}
          </Typography>
          <Typography fontSize={14} color="textSecondary">
            ID: {device?.organization_name ?? ''}
          </Typography>
          <Typography fontSize={14} color="textSecondary">
            Organización: {device?.organization_name ?? ''}
          </Typography>
          {formatDevice && (
            <Typography
              fontSize={14}
              sx={{
                color: formatDevice.color || undefined,
                fontWeight: 'bold',
              }}
            >
              {formatDevice.text ?? ''}
            </Typography>
          )}
          {binnacleValues?.hardwareConfiguration !== null && (
            <>
              <Typography fontSize={14} color="textSecondary">
                Proyecto: {binnacleValues?.profile?.project ?? ''}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                Creación:{' '}
                { binnacleValues?.profile.created ? moment(
                  new Date(
                    binnacleValues?.profile.created
                  ),
                ).format('DD/MM/yyyy hh:mm a') : ' - '}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                Activación:{' '}
                { binnacleValues?.profile?.dateAction ? 
                  moment(
                    new Date(
                      binnacleValues?.profile?.dateAction,
                    ),
                  ).format('DD/MM/yyyy hh:mm a') : ' - '
                }
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                Versión: {binnacleValues?.hardwareConfiguration?.imageVersion ?? ' - '}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to={`/devices-analysis/${device?.id ?? ''}`} style={{ textDecoration: 'none' }}>
          <Button color="info" endIcon={<ArrowRightIcon />} variant="outlined">
            Ver gráfico completo
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography variant="h6">Configuración del Equipo</Typography>
        <Divider />
      </Grid>
      {!binnacleValues?.hardwareConfiguration === null ? (
        <Grid item xs={12} mt={1}>
          <Typography color="textSecondary" sx={{ fontSize: '18px' }}>
            No existe perfil asociado. Completa la configuración del equipo.
          </Typography>
        </Grid>
      ) : (
        <>
        {(binnacleValues?.hardwareConfiguration?.sensorLevelOption?.name ?? '') !== '' && (
          <Grid item xs={6} mt={1}>
            {renderMetrics(binnacleValues?.hardwareConfiguration?.sensorLevelOption.name, 'Mide Nivel')}
            <Typography color="textSecondary" sx={{ fontSize: '12px' }}>
              Número de serie: {binnacleValues?.hardwareConfiguration.serialNumberLevel ?? ''}
            </Typography>
          </Grid>
        )}
        {(binnacleValues?.hardwareConfiguration?.sensorVelocityOption?.name ?? '') !== '' && (
          <Grid item xs={6} mt={1}>
            {renderMetrics(
              binnacleValues?.hardwareConfiguration?.sensorVelocityOption?.name ?? '',
              'Mide Velocidad',
            )}
            <Typography color="textSecondary" sx={{ fontSize: '12px' }}>
              Número de serie: {binnacleValues?.hardwareConfiguration?.serialNumberVelocity ?? ''}
            </Typography>
          </Grid>
        )}
        {(binnacleValues?.hardwareConfiguration?.sensorFlowOption?.name ?? '') !== '' && (
          <Grid item xs={6} mt={1}>
            {renderMetrics(binnacleValues?.hardwareConfiguration?.sensorFlowOption?.name ?? '', 'Mide Flujo')}
            <Typography color="textSecondary" sx={{ fontSize: '12px' }}>
              Número de serie: {binnacleValues?.hardwareConfiguration?.serialNumberFlow ?? ''}
            </Typography>
          </Grid>
        )}
        {(binnacleValues?.hardwareConfiguration?.solarPanelsControllers ?? '') !== '' && (
          <Grid item xs={6} mt={1}>
            <Typography>Controlador solar</Typography>
            <Typography color="textSecondary" sx={{ fontSize: '12px' }}>
              {binnacleValues?.hardwareConfiguration?.solarPanelsControllers ?? ''}
            </Typography>
            <Typography color="textSecondary" sx={{ fontSize: '12px' }}>
              Número de serie:{' '}
              {binnacleValues?.hardwareConfiguration?.solarPanelsControllers ?? ''}
            </Typography>
          </Grid>
        )}
        {(binnacleValues?.hardwareConfiguration?.quantityBattery ?? 0) !== 0 && (
          <Grid item xs={6} mt={1}>
            <Typography>Batería</Typography>
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Cantidad: {binnacleValues?.hardwareConfiguration?.quantityBattery ?? 0}
            </Typography>
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Modelos: {binnacleValues?.hardwareConfiguration?.modelBattery ?? ''}
            </Typography>
          </Grid>
        )}
        {(binnacleValues?.hardwareConfiguration?.quantitySun ?? '') !== '' && (
          <Grid item xs={6} mt={1}>
            <Typography>Panel solar</Typography>
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Cantidad: {binnacleValues?.hardwareConfiguration?.quantitySun ?? 0}
            </Typography>
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Modelos: {binnacleValues?.hardwareConfiguration?.modelsSun ?? ''}
            </Typography>
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Potencia: {binnacleValues?.hardwareConfiguration?.watts ?? ''}
            </Typography>
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Número de serie: {binnacleValues?.hardwareConfiguration?.numberSun ?? ''}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6} mt={1}>
          <Typography>{binnacleValues?.hardwareConfiguration?.internetConnectionOption?.name ?? ''}</Typography>
          {binnacleValues?.hardwareConfiguration?.internetConnectionOption?.name === 'Redes Móviles' && (
            <>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                Modelo: {binnacleValues?.hardwareConfiguration?.modelsConnectionOption?.name ?? ''}
              </Typography>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                IMEI: {binnacleValues?.hardwareConfiguration.imei ?? ''}
              </Typography>
              {(binnacleValues.approved ?? false) && (
                <Typography>Homologado</Typography>
              )}
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                Operador Móvil: {binnacleValues?.hardwareConfiguration.mobileOperatorOption?.name ?? ''}
              </Typography>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                ICCID: {binnacleValues?.hardwareConfiguration.iccid ?? ''}
              </Typography>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                Modelo Antena: {binnacleValues?.hardwareConfiguration?.antennaOption?.name ?? ''}
              </Typography>
            </>
          )}
          {(binnacleValues?.hardwareConfiguration?.internetConnectionOption?.name === 'Red Cableada' ||
            binnacleValues?.hardwareConfiguration?.internetConnectionOption?.name === 'Red Inalámbrica') && (
            <>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                MAC: {binnacleValues?.hardwareConfiguration.mac ?? ''}
              </Typography>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                Configuración Ip: {binnacleValues?.hardwareConfiguration.configurationIp ?? ''}
              </Typography>
              <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
                Administrador: {binnacleValues?.hardwareConfiguration.administrator ?? ''}
              </Typography>
            </>
          )}
          {binnacleValues?.hardwareConfiguration?.internetConnectionOption?.name === 'Red Inalámbrica' && (
            <Typography color="textSecondary" sx={{ fontSize: '14px' }}>
              Nombre Red: {binnacleValues?.hardwareConfiguration.networkName ?? ''}
            </Typography>
          )}
        </Grid>
        {(binnacleValues?.hardwareConfiguration?.observation ?? '') !== '' && (
          <Grid item xs={12} mt={1}>
            <Typography>Observaciones:</Typography>
            <Typography
              color="textSecondary"
              sx={{
                fontSize: '14px',
              }}
            >
              {binnacleValues?.hardwareConfiguration?.observation ?? ''}
            </Typography>
          </Grid>
          )}
        </>
      )}
    </>
  );
};

export default BinnacleValues;
