import { useLazyQuery, useSuspenseQuery } from '@apollo/client';
import { CheckCircle, Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  Button,
  Skeleton,
} from '@mui/material';
import { isNil } from 'lodash';
import moment from 'moment';
import { FC, Suspense, useEffect, useState } from 'react';
import Loading from 'src/components/Loading';
import TablePaginationActions from 'src/components/TablePaginationActions';
import GET_DGA_REPORTS_FOR_TABLE, {
  DGA_REPORTS_TABLE_GET_DEVICE,
} from 'src/features/dga/graphql/queries/getDGAReportsForTable';
import { useAuth, useModalStatusMessage } from 'src/hooks';
import DownloadIcon from 'src/icons/amaruNewIcons/DownloadIcon';
import GET_DGA_HISTORIC_REPORTS_EXCEL from 'src/features/DGAReports/graphql/queries/getDGAHistoricReportsExcel';
import CustomDateTimePicker from 'src/features/customDateTimePicker';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import toast, { ErrorIcon } from 'react-hot-toast';
import { useFormatters } from '@/hooks/useFormatters';
import { DgaType, MetricUnit } from 'src/__generated__/graphql';
import ModalTitle from 'src/components/modal/ModalTitle';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/store';
import { setDGAReportsTableModal } from '../../slices/dgaSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface QuerySortKeys {
  lastEvaluatedKey: string | null;
  lastEvaluatedSortKey: string | null;
}

const Component: FC<{ deviceId: string; onClose: () => void }> = ({ deviceId, onClose }) => {
  const { data } = useSuspenseQuery(DGA_REPORTS_TABLE_GET_DEVICE, {
    variables: { deviceId },
  });
  const [getDGAReports] = useLazyQuery(GET_DGA_REPORTS_FOR_TABLE);

  const { openModalErrorMessage } = useModalStatusMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [items, setItems] = useState([]);
  const [lastQueryKeys, setLastQueryKeys] = useState<QuerySortKeys>({
    lastEvaluatedKey: null,
    lastEvaluatedSortKey: null,
  });

  const handleGetDGAReports = (queryKeys: QuerySortKeys, limit: number) => {
    if (items.length !== 0 && items.length > rowsPerPage * (page + 1)) return;
    setLoading(true);
    getDGAReports({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        deviceId: deviceId,
        lastEvaluatedKey: queryKeys.lastEvaluatedKey,
        lastEvaluatedSortKey: queryKeys.lastEvaluatedSortKey,
        limit: limit,
      },
    })
      .then((response) => {
        const newItems = response?.data?.GetDGAReportsForTable?.items;
        const lastEvaluatedKey = response?.data?.GetDGAReportsForTable?.lastEvaluatedKey;
        const lastEvaluatedSortKey = response?.data?.GetDGAReportsForTable?.lastEvaluatedSortKey;
        if (!isNil(newItems)) {
          setItems([...items, ...newItems]);
        }
        if (!isNil(lastEvaluatedKey) && !isNil(lastEvaluatedSortKey)) {
          setLastQueryKeys({
            lastEvaluatedKey,
            lastEvaluatedSortKey,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        openModalErrorMessage('Algo salió mal. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetDGAReports(lastQueryKeys, rowsPerPage);
    return () => {
      setItems([]);
      setPage(0);
      setRowsPerPage(10);
      setLastQueryKeys({
        lastEvaluatedKey: null,
        lastEvaluatedSortKey: null,
      });
      setLoading(false);
    };
  }, []);

  const handlePageChange = (p) => {
    if (p > page) {
      handleGetDGAReports(lastQueryKeys, rowsPerPage);
    }
    setPage(p);
  };

  const handleRowsPerPageChange = (r) => {
    setRowsPerPage(r);
    if (r > rowsPerPage) {
      handleGetDGAReports(lastQueryKeys, rowsPerPage);
    }
  };

  return (
    <>
      <ModalTitle
        onClose={onClose}
        title={
          <Box display="flex" gap={1}>
            <Trans>Tabla de reportes DGA</Trans> {data.device.profile.name}
          </Box>
        }
      />
      <DialogContent>
        {loading ? (
          <Loading />
        ) : items.length === 0 ? (
          <Typography>No hay datos.</Typography>
        ) : (
          <TableComponent
            items={items}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            deviceId={deviceId}
            dgaType={data.device.dgaConfiguration?.type}
            deviceName={data.device.profile.name}
          />
        )}
      </DialogContent>
    </>
  );
};

const TableComponent: FC<{
  items: any[];
  page: number;
  handlePageChange: (page: number) => void;
  rowsPerPage: number;
  handleRowsPerPageChange: (rowsPerPage: number) => void;
  deviceId: string;
  dgaType?: DgaType | null;
  deviceName: string;
}> = ({
  items,
  page,
  handlePageChange,
  rowsPerPage,
  handleRowsPerPageChange,
  deviceId,
  dgaType,
  deviceName,
}) => {
  const { formatMetricValue, formatDateTime } = useFormatters();
  const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false);

  const handleDownload = () => {
    setShowDownloadDialog(true);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Exitoso</StyledTableCell>
              <StyledTableCell>Fecha y hora</StyledTableCell>
              <StyledTableCell align="left">Número de comprobante (DGA)</StyledTableCell>
              <StyledTableCell align="left">Caudal</StyledTableCell>
              {(dgaType === DgaType.Underground || dgaType === DgaType.Surface) && (
                <StyledTableCell align="left">Altura</StyledTableCell>
              )}
              {(dgaType === DgaType.Underground || dgaType === DgaType.PipeSurface) && (
                <StyledTableCell align="left">Volumen acumulado</StyledTableCell>
              )}
              <StyledTableCell align="left">Mensaje</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="left">
                  {row.success ? <CheckCircle color="success" /> : <ErrorIcon />}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.timestamp != null ? formatDateTime(row.timestamp) : '-'}
                </StyledTableCell>
                <StyledTableCell align="left">{row.numero_comprobante ?? '-'}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.values?.flow != null
                    ? formatMetricValue(row.values.flow, {
                        unit: MetricUnit.LiterPerSecond,
                        precision: 0,
                      })
                    : '-'}
                </StyledTableCell>
                {dgaType === DgaType.Surface && (
                  <StyledTableCell align="left">
                    {row.values?.level != null
                      ? formatMetricValue(row.values.level, {
                          unit: MetricUnit.Centimeter,
                          precision: 0,
                        })
                      : '-'}
                  </StyledTableCell>
                )}
                {dgaType === DgaType.PipeSurface && (
                  <StyledTableCell align="left">
                    {row.values?.accumulated_volume != null
                      ? formatMetricValue(row.values.accumulated_volume, {
                          unit: MetricUnit.CubicMeter,
                          precision: 0,
                        })
                      : '-'}
                  </StyledTableCell>
                )}
                {dgaType === DgaType.Underground && (
                  <>
                    <StyledTableCell align="left">
                      {row.values?.level != null
                        ? formatMetricValue(row.values.level, {
                            unit: MetricUnit.Meter,
                            precision: 0,
                          })
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.values?.accumulated_volume != null
                        ? formatMetricValue(row.values.accumulated_volume, {
                            unit: MetricUnit.CubicMeter,
                            precision: 0,
                          })
                        : '-'}
                    </StyledTableCell>
                  </>
                )}
                <StyledTableCell align="left">{row.error_message ?? '-'}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <Button
            sx={{ color: (theme) => theme.palette.text.secondary }}
            variant="outlined"
            endIcon={<DownloadIcon />}
            onClick={handleDownload}
          >
            Descargar
          </Button>
        </Box>
        <TablePaginationActions
          page={page}
          rowsPerPage={10}
          onChangePage={handlePageChange}
          count={items.length + 1}
          onChangeRowsPerPage={handleRowsPerPageChange}
          showRowsPerPage={false}
        />
      </Box>
      <DownloadDialog
        show={showDownloadDialog}
        handleClose={() => setShowDownloadDialog(false)}
        deviceId={deviceId}
        deviceName={deviceName}
      />
    </Box>
  );
};

// dialog with a date picker to select the date to download the list as excel
const DownloadDialog = ({ show, handleClose, deviceId, deviceName }) => {
  const { user } = useAuth();

  if (!user) return <></>;

  const [absoluteDates, setAbsoluteDates] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment.utc().subtract(1, 'day').startOf('hour').toISOString(),
    endDate: moment.utc().startOf('hour').toISOString(),
  });
  const [loading, setLoading] = useState<boolean>(false);

  // graphql query
  const [getDGAReports] = useLazyQuery(GET_DGA_HISTORIC_REPORTS_EXCEL);

  const handleSubmit = () => {
    setLoading(true);
    getDGAReports({
      fetchPolicy: 'network-only',
      variables: {
        deviceId: deviceId,
        start: moment.utc(absoluteDates.startDate).unix(),
        stop: moment.utc(absoluteDates.endDate).unix(),
        userEmail: user.email,
      },
    })
      .then((response) => {
        handleClose();
        toast.success('Solitud de descarga enviada.');
      })
      .catch((error) => {
        console.log(error);
        toast.error('Algo salió mal. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth={false}>
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">Descargar tabla de reportes DGA</Typography>
            <Typography variant="subtitle2">
              {deviceName} - ID {deviceId}
            </Typography>
          </Box>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box py={2} display="flex" flexDirection="column">
          <Box mb={1}>
            <CustomDateTimePicker
              label="Desde"
              selectedDate={dayjs(absoluteDates.startDate)}
              handleDateChange={(e) => {
                setAbsoluteDates({ ...absoluteDates, startDate: e.toDate().toISOString() });
              }}
              placement="right-start"
            />
          </Box>
          <Box>
            <CustomDateTimePicker
              label="Hasta"
              selectedDate={dayjs(absoluteDates.endDate)}
              handleDateChange={(e) => {
                setAbsoluteDates({ ...absoluteDates, endDate: e.toDate().toISOString() });
              }}
              minDateTime={dayjs(absoluteDates.startDate)}
              placement="right-start"
            />
          </Box>
        </Box>
        <Box width={500}>
          <Typography>
            Se le enviará un correo electrónico con la tabla de reportes DGA en formato excel.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
              Descargar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <ModalTitle
      onClose={onClose}
      title={
        <Box display="flex" gap={1}>
          <Trans>Tabla de reportes DGA</Trans> <Skeleton variant="text" width={200} />
        </Box>
      }
    />
    <DialogContent dividers>
      <Skeleton variant="rounded" height={500} />
    </DialogContent>
  </>
);

const DGAReportsTableModal = () => {
  const dispatch = useDispatch();
  const { reportsTableModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setDGAReportsTableModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={reportsTableModal.open} maxWidth="md" fullWidth>
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {reportsTableModal.open && (
          <Component onClose={onClose} deviceId={reportsTableModal.deviceId} />
        )}
      </Suspense>
    </Dialog>
  );
};

export default DGAReportsTableModal;
