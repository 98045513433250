import { gql } from '@apollo/client';

const UPDATE_SHOW_DEVICE_IN_DGA_LIST = gql`
  mutation UpdateShowDeviceInDGAList($deviceId: String!, $actionType: String!) {
    UpdateShowDeviceInDGAList(deviceId: $deviceId, actionType: $actionType) {
      message
      success
    }
  }
`;

export default UPDATE_SHOW_DEVICE_IN_DGA_LIST;
