import { gql } from '@apollo/client';
// TODO: ivalderrama, evaluar si se sigue utilizando
const CREATE_KANBAN_TASK = gql`
  mutation CreateKanbanTaskMutation(
    $name: String!
    $list_id: String!
    $device_id: String!
    $body: String
    $deviceName: String
    $tags: String
  ) {
    CreateKanbanTask(
      name: $name
      list_id: $list_id
      device_id: $device_id
      body: $body
      deviceName: $deviceName
      tags: $tags
    ) {
      success
      message
    }
  }
`;

export default CREATE_KANBAN_TASK;
