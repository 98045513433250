import { useSuspenseQuery } from '@apollo/client';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { SettingsInputAntenna } from '@mui/icons-material';
import { Box, Avatar, Typography, Skeleton, Link, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import GET_DEVICE_PROFILE_INFO from '../../graphql/queries/getDeviceProfile';
import { Suspense, type FC } from 'react';
import { useFormatters } from 'src/hooks/useFormatters';

interface Props {
  deviceId: string;
}

const DeviceProfileInfo: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const { formatRelativeTime } = useFormatters();
  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_INFO, {
    variables: {
      input: {
        id: deviceId,
      },
    },
  });

  const id = data.device.id;
  const picture = data.device.hardwareConfiguration?.images;
  const { type, name, organization, irrigation, project } = data.device.profile;
  const organizationName = organization?.name ?? null;
  const irrigationName = irrigation?.name ?? null;
  const relativeCreated = formatRelativeTime(data.device.profile.created);
  const deviceTypeColor = 'red';

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      gap={2}
      px={3}
      alignItems="center"
    >
      {picture ? (
        <Avatar sx={{ width: 96, height: 96 }} src={picture} alt={_(msg`Imagen de perfil`)} />
      ) : (
        <Avatar sx={{ width: 96, height: 96, fontSize: 48, bgcolor: red[500] }}>
          <SettingsInputAntenna fontSize="inherit" />
        </Avatar>
      )}
      <Box width="100%">
        <Typography fontSize={24} fontWeight="bold" lineHeight={1} mb={{ xs: 1, sm: 0 }}>
          {name}
        </Typography>
        <Typography color="text.secondary" mb={{ xs: 0, sm: 1 }}>
          ID: <b>{id}</b>
        </Typography>
        <Box
          display="grid"
          columnGap={4}
          sx={{
            gridTemplate: {
              xs: 'repeat(6, 1fr) / 1fr',
              sm: 'repeat(3, 1fr) / repeat(2, 1fr)',
            },
          }}
          gridAutoFlow="column"
        >
          <Typography color="text.secondary">
            Tipo: <b style={{ color: deviceTypeColor }}>{type}</b>
          </Typography>
          <Typography color="text.secondary" maxWidth={250} noWrap>
            Organización:{' '}
            <Tooltip title={organizationName ?? ''}>
              <b>{organizationName ?? '-'}</b>
            </Tooltip>
          </Typography>
          <Typography color="text.secondary" maxWidth={250} noWrap>
            Irrigación:{' '}
            <Tooltip title={irrigationName ?? ''}>
              <b>{irrigationName ?? '-'}</b>
            </Tooltip>
          </Typography>
          <Typography color="text.secondary">
            Proyecto: <b>{project ?? '-'}</b>
          </Typography>
          <Typography color="text.secondary">
            Ubicación:{' '}
            <b>
              <Link href="#">Ver en GMaps</Link>
            </b>
          </Typography>
          <Typography color="text.secondary">
            Creado <b>{relativeCreated}</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const DeviceProfileInfoSkeleton = () => (
  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={4} alignItems="center">
    <Skeleton variant="circular" width={96} height={96} />
    <Box>
      <Typography fontSize={24} fontWeight="bold" lineHeight={1}>
        <Skeleton width={400} />
      </Typography>
      <Typography color="text.secondary" gutterBottom>
        <Skeleton width={200} />
      </Typography>
      <Box
        display="grid"
        columnGap={10}
        // gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}
        // gridTemplateRows={{ xs: 'repeat(6, 1fr)', sm: 'repeat(3, 1fr) / repeat(2, 1fr)' }}
        sx={{
          gridTemplate: {
            xs: '1fr / repeat(6, 1fr)',
            sm: '1fr 1fr',
          },
        }}
        gridAutoFlow="column"
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <Typography key={index} color="text.secondary">
            <Skeleton width={160} />
          </Typography>
        ))}
      </Box>
    </Box>
  </Box>
);

const Component: FC<Props> = ({ deviceId }) => (
  <Suspense fallback={<DeviceProfileInfoSkeleton />}>
    <DeviceProfileInfo deviceId={deviceId} />
  </Suspense>
);

export default Component;
