import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import { get, isNil } from 'lodash';
import { getDeviceStatusFormat } from 'src/utils/devices/';
import { useState, useEffect } from 'react';
import genGQL from 'src/graphql/querys/gqlGenerator';
import moment from 'moment';
import { DeviceDialogProps } from '../dialogInterfaces';

const StatusMaintenance = ({ show, device, handleClose }: DeviceDialogProps) => {
  const client = useApolloClient();
  const statusValue = getDeviceStatusFormat(get(device, 'status', 'active'));
  const [status, setStatus] = useState(null);

  const fetchHistory = async (deviceId) => {
    try {
      const attributes = ['id', 'status', 'description', 'account', 'created'];
      const subAttributes = {
        account: {
          attributes: ['name', 'lastnames', 'username', 'id'],
        },
      };
      const result = get(
        await client.query({
          query: genGQL(
            'query',
            'GateMaintenance',
            [{ name: 'deviceId', type: 'String!' }],
            attributes,
            subAttributes,
          ),
          fetchPolicy: 'no-cache',
          variables: {
            deviceId: deviceId,
          },
        }),
        'data.GateMaintenance',
        null,
      );

      return result;
    } catch (error) {
      console.log('error', error);
    }
    return null;
  };

  useEffect(() => {
    if (get(device, 'parent_component_id', null)) {
      (async () => {
        const result = await fetchHistory(get(device, 'id'));
        if (result && result.length) {
          setStatus(result[0]);
        } else {
          setStatus(null);
        }
      })();
    }
  }, [device]);

  const formatDate = (item) => {
    const date = get(item, 'created', null);
    if (!isNil(date)) {
      return moment(parseInt(date, 10)).format('DD/MM/yyyy hh:mm:a');
    }
    return '';
  };

  const handleCloseWithoutLoading = () => {
    handleClose(false);
  };

  const renderData = (title, data) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '20px',
      }}
    >
      <Typography>{title}</Typography>
      <Typography>{data}</Typography>
    </Box>
  );

  return (
    <Dialog open={show} onClose={handleCloseWithoutLoading}>
      <DialogTitle>Información de la solicitud del estado</DialogTitle>
      <DialogContent>
        {status ? (
          <Box>
            {renderData('Solicitado por:', get(status, 'account.name', null))}
            {renderData('Email', get(status, 'account.username', null))}
            {renderData('Estado solicitado:', get(statusValue, 'name'))}
            {renderData('Fecha:', formatDate(status))}
            {renderData('Descripción:', get(status, 'description', null))}
          </Box>
        ) : (
          <Box>
            <Typography>No hay datos de cambio</Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="row" py={2}>
          <Button variant="contained" color="primary" onClick={handleCloseWithoutLoading}>
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StatusMaintenance;
