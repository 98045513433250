import { gql } from '@apollo/client';

const KANBAN_TASK = gql`
  subscription KanbanTasksSubscription(
    $accountId: Int!
    $organizationId: Int!
  ) {
    KanbanTasks(accountId: $accountId, organizationId: $organizationId) {
      id
      name
      created
      created_index
      list_id
      device_id
      tags
      account {
        id
        name
        username
        picture
      }
      args {
        type
        sourceId
        destinationId
        sourceCreated
        destinationCreated
      }
    }
  }
`;

export default KANBAN_TASK;
