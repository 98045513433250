import { Dialog, DialogContent, DialogTitle, Box, Typography, Grid } from '@mui/material';
import { type FC, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CHANGE_DGA_TICKET_STATE_TO_CLOSE from '@features/DGAReports/graphql/queries/changeDGATicketStateToClose';
import Loading from '@components/Loading';
import ActionButtons from 'src/components/ActionButtons';
import { useModalStatusMessage } from 'src/hooks';
import { useFormatters } from 'src/hooks/useFormatters';
import { DgaTicket } from 'src/__generated__/graphql';
import GET_DGA_TICKET_STATE from '../../graphql/queries/getDGATicketState';
import { useSelector } from 'src/store';
import { setDGATicketStateModal } from '../../slices/dgaSlice';
import { useDispatch } from 'react-redux';

const CloseTicketConfirmation = ({ show, handleClose, handleConfirmation }) => {
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>¿Está seguro que desea cerrar el ticket DGA?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              El estado del ticket pasará a "cerrado", por lo tanto, no se considerará para las
              notificaciones.
            </Typography>
            <Typography>
              La fecha y hora de cambio de estado se almacenará en base de datos.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ActionButtons
              closeFunction={handleClose}
              actionFunction={handleConfirmation}
              actionButtonText="Confirmar"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

interface Props {}

const UpdateDGATicketState: FC<Props> = () => {
  const { dgaTicketStateModal } = useSelector((state) => state.dga_store);
  const { formatDateTime } = useFormatters();
  const [showChangeStateToCloseConfirmation, setShowChangeStateToCloseConfirmation] =
    useState(false);
  const [changeDGATicketStateToClose] = useMutation(CHANGE_DGA_TICKET_STATE_TO_CLOSE);
  const [loading, setLoading] = useState(false);
  const deviceId = dgaTicketStateModal.open && dgaTicketStateModal.deviceId;
  const { data, loading: dataLoading } = useQuery(GET_DGA_TICKET_STATE, {
    fetchPolicy: 'no-cache',
    variables: {
      deviceId,
    },
    skip: !deviceId,
  });
  const dispatch = useDispatch();

  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();

  const handleClose = () => {
    dispatch(
      setDGATicketStateModal({
        open: false,
      }),
    );
  };

  const dgaTicket = data?.device.dgaTicket;
  const show = dgaTicketStateModal.open;

  if (!deviceId) {
    return null;
  }

  const handleCloseWithoutLoading = () => {
    handleClose(false);
  };

  const handleChangeTicketStateToEnded = () => {
    setShowChangeStateToCloseConfirmation(true);
  };

  const handleCloseTicketConfirmationClose = () => {
    setShowChangeStateToCloseConfirmation(false);
  };

  const modalErrorMessage = (message?: string) => {
    openModalErrorMessage(message || 'Error al intentar cerrar el ticket DGA.');
  };

  const handleCloseTicketConfirmation = () => {
    if (!dgaTicket) return;
    setShowChangeStateToCloseConfirmation(false);
    setLoading(true);
    changeDGATicketStateToClose({
      variables: {
        deviceId,
        ticketTimestamp: dgaTicket.timestamp,
        ticketID: dgaTicket.id,
      },
    })
      .then((response) => {
        const data = response?.data?.ChangeDGATicketStateToClose;
        const success = data?.success;
        if (success) {
          openModalSuccessMessage('Ticket DGA cerrado con éxito.');
          handleInternalClose(true);
        } else {
          modalErrorMessage();
        }
      })
      .catch((error) => {
        console.log(error);
        modalErrorMessage();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInternalClose = (refetch) => {
    setShowChangeStateToCloseConfirmation(false);
    handleClose(refetch);
  };

  return (
    <Dialog open={show} onClose={handleCloseWithoutLoading}>
      <DialogTitle>Ticket DGA creado para ID {deviceId}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Loading />
          </Box>
        ) : (
          <Box width={450}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography>Ticket ID:</Typography>
                  <Typography>{dgaTicket?.id}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography>Fecha y hora del Ticket:</Typography>
                  <Typography>
                    {dgaTicket?.timestamp ? formatDateTime(dgaTicket?.timestamp) : '-'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ActionButtons
                  closeFunction={handleCloseWithoutLoading}
                  actionFunction={handleChangeTicketStateToEnded}
                  actionButtonText="Cerrar Ticket DGA"
                  actionButtonColor="error"
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <CloseTicketConfirmation
        show={showChangeStateToCloseConfirmation}
        handleClose={handleCloseTicketConfirmationClose}
        handleConfirmation={handleCloseTicketConfirmation}
      />
    </Dialog>
  );
};

export default UpdateDGATicketState;
