import { t } from '@lingui/macro';

export const getTextStatus = () => ({
  active: {
    name: 'Activo',
    text: 'puesto a estado ACTIVO',
    title: '¿Está seguro de que deseas ACTIVAR el estado del dispositivo',
  },
  maintenance: {
    name: 'En Mantenimiento',
    text: 'puesto a estado de EN MANTENIMIENTO',
    title: '¿Está seguro de que deseas ACTIVAR el estado en Mantenimiento del dispositivo',
    color: '#ffc107',
  },
  testing: {
    name: 'En Prueba',
    text: 'puesto a estado de EN PRUEBA',
    title: '¿Está seguro de que deseas ACTIVAR el estado en prueba del dispositivo',
    color: '#ff6207',
  },
});

export const getStatusSelectAdmin = () => [
  {
    key: 1,
    name: t`Activo`,
    status: 'active',
  },
  {
    key: 2,
    name: t`En Mantenimiento`,
    status: 'maintenance',
  },
  {
    key: 3,
    name: t`En prueba`,
    status: 'testing',
  },
];

export const getStatusSelectAccount = () => [
  {
    key: 1,
    name: t`Activo`,
    status: 'active',
  },
  {
    key: 2,
    name: t`En Mantenimiento`,
    status: 'maintenance',
  },
];
