import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from '@/store';
import { closeDeviceProfileModal } from '../slices/deviceProfileSlice';
import { type FC } from 'react';
import DeviceProfileRecentActivity from './modal/RecentActivity';
import DeviceProfileObservations from './modal/Observations';
import DeviceProfileStatus from './modal/Status';
import DeviceProfileInfo from './modal/Info';

const DeviceProfileModal = () => {
  const dispatch = useDispatch();
  const {
    deviceProfileModal: { deviceId, open },
  } = useSelector((state) => state.device_profile_store);

  const handleClose = () => {
    dispatch(closeDeviceProfileModal());
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      {deviceId && <DeviceProfileModalContent deviceId={deviceId} />}
    </Dialog>
  );
};

interface Props {
  deviceId: string;
}

const DeviceProfileModalContent: FC<Props> = ({ deviceId }) => {
  return (
    <Box display="grid" gridTemplateRows="auto 1fr">
      <Box
        display="grid"
        py={3}
        px={2}
        rowGap={2}
        gridTemplateColumns={{
          sx: '1fr',
          md: 'auto 1fr',
        }}
        justifyItems={{ xs: 'start', sm: 'center' }}
      >
        <DeviceProfileInfo deviceId={deviceId} />
        <DeviceProfileStatus deviceId={deviceId} />
      </Box>
      <Box>
        <Divider />
        {/* <Box px={4} py={2}> */}
        {/*   <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={1}> */}
        {/*     <Typography variant="h5" gutterBottom> */}
        {/*       Estado */}
        {/*     </Typography> */}
        {/*     <Button variant="contained" color="info" size="small"> */}
        {/*       Ver más */}
        {/*     </Button> */}
        {/*   </Box> */}
        {/* </Box> */}
        {/* <Divider /> */}
        <Box display="grid" gridTemplateColumns={{ sm: '1fr', md: '1fr auto 1fr' }}>
          <Box p={2} pl={{ xs: 2, md: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={1}>
              <Typography variant="h5">Observaciones</Typography>
              <Button variant="contained" color="info" size="small">
                Ver más
              </Button>
            </Box>
            <DeviceProfileObservations deviceId={deviceId} />
          </Box>
          <Divider
            sx={{
              display: {
                sm: 'none',
                md: 'block',
              },
            }}
            orientation="vertical"
          />
          <Divider
            sx={{
              display: {
                sm: 'block',
                md: 'none',
              },
            }}
            orientation="horizontal"
          />
          <Box p={2} pr={{ xs: 2, md: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={1}>
              <Typography variant="h5">Actividad reciente</Typography>
              <Button variant="contained" color="info" size="small">
                Ver más
              </Button>
            </Box>
            <DeviceProfileRecentActivity deviceId={deviceId} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceProfileModal;
