import { gql } from '@/__generated__';

const UPDATE_ACCOUNT_ACCESS_AND_ALERTS = gql(`
  mutation updateAccountAccessAndAlerts(
    $input: AccountAccessAndAlertsInput!
  ) {
    updateAccountAccessAndAlerts(
      input: $input
    ) {
      success
      message
    }
  }
`);
export default UPDATE_ACCOUNT_ACCESS_AND_ALERTS;