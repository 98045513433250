import { ChangeDgaScheduleInput, DayOfWeek, DgaFrequency } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useDispatch, useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import NumericTextField from '@components/NumericTextField';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Switch,
  TextField,
} from '@mui/material';
import { Suspense, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import StandardAutocompleteIds from 'src/features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import UPDATE_DGA_SCHEDULE from '../../graphql/mutations/updateDgaSchedule';
import GET_DGA_CONFIGURATION_SCHEDULE from '../../graphql/queries/getDGAConfigurationSchedule';
import { setUpdateDGAScheduleModal } from '../../slices/dgaSlice';
import { getDgaScheduleValidationSchema } from '../../utils/validationSchemas';

type FormValues = ChangeDgaScheduleInput;

interface Props {
  onClose: () => void;
  deviceId: string;
}

const Component: FC<Props> = ({ onClose, deviceId }) => {
  const { i18n, _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_DGA_CONFIGURATION_SCHEDULE, {
    variables: { deviceInput: { id: deviceId } },
  });

  const defaultValues: FormValues = {
    deviceId,
    enable: data.device.dgaConfiguration?.enabled ?? false,
    hour: data.device.dgaConfiguration?.hour ?? null,
    frequency: data.device.dgaConfiguration?.frequency ?? DgaFrequency.Monthly,
    weekday: data.device.dgaConfiguration?.weekday ?? DayOfWeek.Monday,
    day: data.device.dgaConfiguration?.day ?? 1,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(getDgaScheduleValidationSchema(i18n)),
  });

  const [updateDgaSchedule, { loading }] = useMutation(UPDATE_DGA_SCHEDULE, {
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
    onCompleted: (data) => {
      openModalSuccessMessage(_(msg`Recurrencia de envíos actualizada correctamente`));
      methods.reset({
        deviceId,
        enable: data.updateDgaSchedule.enabled ?? false,
        hour: data.updateDgaSchedule.hour ?? null,
        frequency: data.updateDgaSchedule.frequency ?? DgaFrequency.Monthly,
        weekday: data.updateDgaSchedule.weekday ?? DayOfWeek.Monday,
        day: data.updateDgaSchedule.day ?? 1,
      });
      setTimeout(() => {
        onClose();
      }, 100);
    },
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Device',
          id: deviceId,
        }),
        fields: {
          dgaConfiguration() {
            return data.updateDgaSchedule;
          },
        },
      });
    },
    async onQueryUpdated(observableQuery) {
      if (observableQuery.queryName !== 'StandardAutocompleteData') {
        return await observableQuery.refetch();
      }
    },
  });

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = (values: FormValues) => {
    if (values.enable === false) {
      values = {
        ...defaultValues,
        enable: false,
      };
    }

    updateDgaSchedule({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  const enableSchedule = methods.watch('enable');
  const frequency = methods.watch('frequency');

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Controller
          name="deviceId"
          control={methods.control}
          render={({ field, fieldState }) => (
            <StandardAutocompleteIds
              dataType="devices"
              label={_(msg`Dispositivo`)}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              value={field.value || null}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              disabled
            />
          )}
        />
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <Controller
            control={methods.control}
            name="enable"
            render={({ field: { value, ref, ...rest } }) => (
              <FormGroup>
                <FormControlLabel
                  {...rest}
                  checked={value}
                  inputRef={ref}
                  control={<Switch />}
                  label={_(msg`Habilitar envíos DGA`)}
                />
              </FormGroup>
            )}
          />

          {enableSchedule && (
            <>
              <Controller
                name="frequency"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={Object.values(DgaFrequency)}
                    disableClearable
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(value) => {
                      if (value === DgaFrequency.Daily) return _(msg`Diario`);
                      if (value === DgaFrequency.Weekly) return _(msg`Semanal`);
                      if (value === DgaFrequency.Monthly) return _(msg`Mensual`);
                      if (value === DgaFrequency.Hourly) return _(msg`Cada hora`);
                      return value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={_(msg`Frecuencia`)}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
              {frequency === DgaFrequency.Weekly && (
                <Controller
                  name="weekday"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      options={[
                        DayOfWeek.Monday,
                        DayOfWeek.Tuesday,
                        DayOfWeek.Wednesday,
                        DayOfWeek.Thursday,
                        DayOfWeek.Friday,
                        DayOfWeek.Saturday,
                        DayOfWeek.Sunday,
                      ]}
                      disableClearable
                      value={field.value || undefined}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      getOptionLabel={(value) => {
                        if (value === DayOfWeek.Monday) return _(msg`Lunes`);
                        if (value === DayOfWeek.Tuesday) return _(msg`Martes`);
                        if (value === DayOfWeek.Wednesday) return _(msg`Miércoles`);
                        if (value === DayOfWeek.Thursday) return _(msg`Jueves`);
                        if (value === DayOfWeek.Friday) return _(msg`Viernes`);
                        if (value === DayOfWeek.Saturday) return _(msg`Sábado`);
                        if (value === DayOfWeek.Sunday) return _(msg`Domingo`);
                        return value;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={_(msg`Día de la semana`)}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              )}
              {frequency === DgaFrequency.Monthly && (
                <Controller
                  control={methods.control}
                  name="day"
                  render={({ field, fieldState }) => (
                    <NumericTextField
                      {...field}
                      label={_(msg`Día del mes`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      inputMode="numeric"
                      value={Number.isNaN(field.value) ? '' : field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') field.onChange(NaN);
                        else field.onChange(value);
                      }}
                    />
                  )}
                />
              )}
              {frequency !== DgaFrequency.Hourly && (
                <Controller
                  control={methods.control}
                  name="hour"
                  render={({ field, fieldState }) => (
                    <NumericTextField
                      {...field}
                      label={_(msg`Hora`)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={Number.isNaN(field.value) ? '' : field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '') field.onChange(NaN);
                        else field.onChange(value);
                      }}
                    />
                  )}
                />
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={resetForm}
        dirtyForm={methods.formState.isDirty}
        submitLoading={loading}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
    <ModalActions onClose={onClose} />
  </>
);

const UpdateDGAScheduleModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDGAScheduleModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setUpdateDGAScheduleModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={updateDGAScheduleModal.open} maxWidth="sm" fullWidth>
      <ModalTitle onClose={onClose} title={<Trans>Editar recurrencia de envíos DGA</Trans>} />
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {updateDGAScheduleModal.open && (
          <Component onClose={onClose} deviceId={updateDGAScheduleModal.deviceId} />
        )}
      </Suspense>
    </Dialog>
  );
};

export default UpdateDGAScheduleModal;
