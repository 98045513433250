import CreateDGAConfigurationModal from './CreateDGAConfigurationModal';
import DGAReportsRetryModal from './DGAReportsRetryModal';
import DGAUpdateMaxFlowModal from './DGAUpdateMaxFlowModal';
import DGAReportsChartModal from './ReportsChartModal';
import DGAReportsTableModal from './ReportsTableModal';
import UpdateDGAConfigurationModal from './UpdateDGAConfigurationModal';
import UpdateDGAScheduleModal from './UpdateDGAScheduleModal';
import UpdateDGATicketState from './UpdateDGATicketStateModal';
import { TicketConfiguration } from './ticketConfiguration';

const DGAModals = () => {
  return (
    <>
      <CreateDGAConfigurationModal />
      <UpdateDGAConfigurationModal />
      <UpdateDGAScheduleModal />
      <DGAReportsChartModal />
      <DGAReportsTableModal />
      <DGAReportsRetryModal />
      <DGAUpdateMaxFlowModal />
      <TicketConfiguration />
      <UpdateDGATicketState />
    </>
  );
};

export default DGAModals;
