import {
  MutationTuple,
  QueryReference,
  useLoadableQuery,
  useMutation,
  useReadQuery,
} from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { type FC, Suspense, useState, useEffect } from 'react';
import ModalActions from 'src/components/modal/ModalActions';
import ModalTitle from 'src/components/modal/ModalTitle';
import GET_DGA_CONFIGURATION from '../../graphql/queries/getDGAConfiguration';
import { Controller, useForm } from 'react-hook-form';
import StandardAutocompleteIds from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import {
  DgaType,
  GetDgaConfigurationQuery,
  GetDgaConfigurationQueryVariables,
  UpdateDgaConfigInput,
  UpdateDgaConfigurationMutation,
  UpdateDgaConfigurationMutationVariables,
} from '@/__generated__/graphql';
import { Info } from '@mui/icons-material';
import { useDispatch, useSelector } from 'src/store';
import {
  setCreateDGAConfigurationModal,
  setUpdateDGAConfigurationModal,
  setUpdateDGAScheduleModal,
} from '../../slices/dgaSlice';
import { useModalStatusMessage } from '@/hooks';
import DeleteDGAConfigurationConfirmationModal from './DeleteDGAConfigurationConfirmationModal';
import { zodResolver } from '@hookform/resolvers/zod';
import UPDATE_DGA_CONFIGURATION from '../../graphql/mutations/updateDgaConfiguration';
import { getDgaConfigurationValidationSchema } from '../../utils/validationSchemas';
import { useLingui } from '@lingui/react';
import UpdateDGAScheduleModal from './UpdateDGAScheduleModal';

type FormValues = UpdateDgaConfigInput;

const Component: FC<{
  onClose: () => void;
  mutation: MutationTuple<UpdateDgaConfigurationMutation, UpdateDgaConfigurationMutationVariables>;
  queryRef: QueryReference<GetDgaConfigurationQuery, GetDgaConfigurationQueryVariables>;
}> = ({ onClose, mutation, queryRef }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { i18n, _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { data } = useReadQuery(queryRef);
  const deviceId = data.device.id;
  const [updateDgaConfiguration, { loading }] = mutation;

  const defaultValues = {
    deviceId,
    code: data.device?.dgaConfiguration?.code || '',
    rut: data.device?.dgaConfiguration?.rut || '',
    password: data.device?.dgaConfiguration?.password || '',
    type: data.device?.dgaConfiguration?.type || DgaType.Surface,
  };

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(getDgaConfigurationValidationSchema(i18n)),
  });

  const hasDGAConfiguration = data?.device?.dgaConfiguration != null;

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = async (values: FormValues) => {
    await updateDgaConfiguration({
      variables: {
        input: {
          ...values,
        },
      },
      onError: (error) => {
        openModalErrorMessage(error.message);
      },
      onCompleted: (data) => {
        openModalSuccessMessage(_(msg`Configuración DGA actualizada correctamente`));
        methods.reset({
          deviceId,
          code: data.updateDgaConfiguration.code,
          rut: data.updateDgaConfiguration.rut,
          password: data.updateDgaConfiguration.password,
          type: data.updateDgaConfiguration.type,
        });
        onClose();
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify(data.updateDgaConfiguration),
          fields: {
            code() {
              return data.updateDgaConfiguration.code;
            },
            rut() {
              return data.updateDgaConfiguration.rut;
            },
            password() {
              return data.updateDgaConfiguration.password;
            },
            type() {
              return data.updateDgaConfiguration.type;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
      awaitRefetchQueries: true,
    });
  };

  const handleCreateConfiguration = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: false,
      }),
    );
    dispatch(
      setCreateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <>
      <DeleteDGAConfigurationConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deviceId={deviceId}
        onSuccesfulDelete={onClose}
      />
      <UpdateDGAScheduleModal />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Controller
            name="deviceId"
            control={methods.control}
            render={({ field, fieldState }) => (
              <StandardAutocompleteIds
                dataType="devices"
                label={_(msg`Dispositivo`)}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                value={field.value || null}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled
              />
            )}
          />
          {!hasDGAConfiguration ? (
            <Box mt={2}>
              <Box display="flex" gap={1} alignItems="center" mb={1}>
                <Info fontSize="small" color="warning" />
                <Typography>
                  <Trans>El dispositivo no tiene configuración DGA</Trans>
                </Typography>
              </Box>
              <Button variant="contained" color="primary" onClick={handleCreateConfiguration}>
                <Trans>Crear configuración</Trans>
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
              <Controller
                name="code"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Código DGA"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="rut"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="RUT"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    label="Contraseña"
                    autoComplete="new-password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="type"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    options={Object.values(DgaType)}
                    disableClearable
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    getOptionLabel={(value) => {
                      if (value === DgaType.Surface) return 'Superficial';
                      if (value === DgaType.PipeSurface) return 'Superficial con flujómetro';
                      if (value === DgaType.Underground) return 'Subterránea';
                      return value;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de extracción"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
          )}
        </DialogContent>
        <ModalActions
          onClose={onClose}
          onSubmit="submit"
          onResetForm={resetForm}
          dirtyForm={!hasDGAConfiguration ? false : methods.formState.isDirty}
          submitLoading={loading}
          customButtons={[
            {
              label: _(msg`Eliminar configuración`),
              color: 'error',
              onClick: () => {
                setOpenDeleteModal(true);
              },
              disabled: !hasDGAConfiguration,
            },
            {
              label: _(msg`Editar recurrencia`),
              onClick: () => {
                dispatch(
                  setUpdateDGAScheduleModal({
                    open: true,
                    deviceId,
                  }),
                );
              },
              disabled: !hasDGAConfiguration,
            },
          ]}
        />
      </form>
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
    <ModalActions onClose={onClose} />
  </>
);

const UpdateDGAConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDGAConfigurationModal } = useSelector((state) => state.dga_store);
  const mutation = useMutation(UPDATE_DGA_CONFIGURATION);
  const [loadQuery, queryRef] = useLoadableQuery(GET_DGA_CONFIGURATION, {});

  const onClose = () => {
    dispatch(
      setUpdateDGAConfigurationModal({
        open: false,
      }),
    );
  };

  useEffect(() => {
    if (updateDGAConfigurationModal.open) {
      loadQuery({
        deviceId: updateDGAConfigurationModal.deviceId,
      });
    }
  }, [updateDGAConfigurationModal.open]);

  return (
    <Dialog onClose={onClose} open={updateDGAConfigurationModal.open} maxWidth="md" fullWidth>
      <ModalTitle onClose={onClose} title={<Trans>Editar configuración DGA</Trans>} />
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {queryRef && <Component onClose={onClose} mutation={mutation} queryRef={queryRef} />}
      </Suspense>
    </Dialog>
  );
};

export default UpdateDGAConfigurationModal;
