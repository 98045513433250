import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ModalStateRequiredDevice =
  | {
      open: true;
      deviceId: string;
    }
  | {
      open: false;
    };

interface DGAState {
  createDGAConfigurationModal: {
    open: boolean;
    deviceId?: string;
  };
  updateDGAConfigurationModal: ModalStateRequiredDevice;
  updateDGAScheduleModal: ModalStateRequiredDevice;
  updateDGAMaxFlowModal: ModalStateRequiredDevice;
  updateDGATicketModal: ModalStateRequiredDevice;
  reportsChartModal: ModalStateRequiredDevice;
  reportsTableModal: ModalStateRequiredDevice;
  retryReportsModal: ModalStateRequiredDevice;
  dgaTicketStateModal: ModalStateRequiredDevice;
}

const initialState: DGAState = {
  createDGAConfigurationModal: {
    open: false,
  },
  updateDGAConfigurationModal: {
    open: false,
  },
  updateDGAScheduleModal: {
    open: false,
  },
  reportsChartModal: {
    open: false,
  },
  reportsTableModal: {
    open: false,
  },
  retryReportsModal: {
    open: false,
  },
  updateDGAMaxFlowModal: {
    open: false,
  },
  updateDGATicketModal: {
    open: false,
  },
  dgaTicketStateModal: {
    open: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'dga_store',
  initialState,
  reducers: {
    setCreateDGAConfigurationModal(
      state: DGAState,
      action: PayloadAction<DGAState['createDGAConfigurationModal']>,
    ) {
      state.createDGAConfigurationModal = action.payload;
    },
    setUpdateDGAConfigurationModal(
      state: DGAState,
      action: PayloadAction<DGAState['updateDGAConfigurationModal']>,
    ) {
      state.updateDGAConfigurationModal = action.payload;
    },
    setUpdateDGAScheduleModal(
      state: DGAState,
      action: PayloadAction<DGAState['updateDGAScheduleModal']>,
    ) {
      state.updateDGAScheduleModal = action.payload;
    },
    setDGAReportsChartModal(state: DGAState, action: PayloadAction<DGAState['reportsChartModal']>) {
      state.reportsChartModal = action.payload;
    },
    setDGAReportsTableModal(state: DGAState, action: PayloadAction<DGAState['reportsTableModal']>) {
      state.reportsTableModal = action.payload;
    },
    setRetryReportsModal(state: DGAState, action: PayloadAction<DGAState['retryReportsModal']>) {
      state.retryReportsModal = action.payload;
    },
    setUpdateDGAMaxFlowModal(
      state: DGAState,
      action: PayloadAction<DGAState['updateDGAMaxFlowModal']>,
    ) {
      state.updateDGAMaxFlowModal = action.payload;
    },
    setUpdateDGATicketModal(
      state: DGAState,
      action: PayloadAction<DGAState['updateDGATicketModal']>,
    ) {
      state.updateDGATicketModal = action.payload;
    },
    setDGATicketStateModal(
      state: DGAState,
      action: PayloadAction<DGAState['dgaTicketStateModal']>,
    ) {
      state.dgaTicketStateModal = action.payload;
    },
  },
});

export default reducer;
export const {
  setUpdateDGAConfigurationModal,
  setCreateDGAConfigurationModal,
  setUpdateDGAScheduleModal,
  setDGAReportsChartModal,
  setDGAReportsTableModal,
  setRetryReportsModal,
  setUpdateDGAMaxFlowModal,
  setUpdateDGATicketModal,
  setDGATicketStateModal,
} = actions;
