import { useApolloClient } from '@apollo/client';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import genGQL from 'src/graphql/querys/gqlGenerator';
import { DeviceDialogProps } from '../dialogInterfaces';

const COLORSTATUS: any = {
  active: {
    name: 'Activo',
    color: '#00e394b6',
  },
  maintenance: {
    name: 'En Mantenimiento',
    color: '#ffc107',
  },
  testing: {
    name: 'En Prueba',
    color: '#ff6207',
  },
};

const HistoryStatusDevice = ({
  show,
  device,
  handleClose,
}: DeviceDialogProps) => {
  const client = useApolloClient();
  const [history, setHistory] = useState([]);

  const fetchHistory = async (deviceId) => {
    try {
      const attributes = ['id', 'status', 'description', 'account', 'created'];
      const subAttributes = {
        account: {
          attributes: ['name', 'lastnames', 'username', 'id'],
        },
      };
      const result = _.get(
        await client.query({
          query: genGQL(
            'query',
            'GateMaintenance',
            [{ name: 'deviceId', type: 'String!' }],
            attributes,
            subAttributes,
          ),
          fetchPolicy: 'no-cache',
          variables: {
            deviceId: deviceId,
          },
        }),
        'data.GateMaintenance',
        null,
      );

      return result;
    } catch (error) {
      console.log('error', error);
    }
    return null;
  };

  const handleGetHistory = async (deviceId) => {
    const result = await fetchHistory(deviceId);
    console.log('results', result);
    if (!_.isNil(result)) {
      setHistory(result);
    } else {
      setHistory(null);
    }
  };

  useEffect(() => {
    const deviceId = _.get(device, 'id', null);
    if (show && !_.isNil(deviceId)) {
      handleGetHistory(deviceId);
    }
    if (!show) {
      setHistory(null);
    }
  }, [device, show]);

  const onClose = () => {
    handleClose(false);
  };

  const formatDate = (item: any) => {
    const date = _.get(item, 'created', null);
    if (!_.isNil(date)) {
      return moment(JSON.parse(date)).format('DD/MM/yyyy hh:mm:a');
    }
    return '';
  };

  const chipStatusDevices = (valueStatus: string) => {
    const valueColorsStatus = COLORSTATUS[valueStatus];
    return (
      <>
        <Chip
          label={_.get(valueColorsStatus, 'name', '')}
          sx={{
            backgroundColor: _.get(valueColorsStatus, 'color', 'transparent'),
            fontWeight: 'bold',
            color: 'black',
            borderRadius: '5px',
          }}
        />
      </>
    );
  };

  return (
    <Dialog open={show} onClose={onClose} fullWidth maxWidth={false}>
      <DialogTitle>Historial de estados del dispositivo</DialogTitle>
      <DialogContent>
        <Grid container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID de solicitud</TableCell>
                  <TableCell>Solicitado por</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isNil(history) &&
                  history.map((item) => (
                    <TableRow key={_.get(item, 'id', null)}>
                      <TableCell>{_.get(item, 'id', '')}</TableCell>
                      <TableCell>
                        {_.get(item, 'account.name', '')}{' '}
                        {_.get(item, 'account.lastnames', '')}
                      </TableCell>
                      <TableCell>
                        <div className="quill-comments">
                          {_.get(item, 'description', '')}
                        </div>
                      </TableCell>
                      <TableCell>{formatDate(item)}</TableCell>
                      <TableCell>
                        {chipStatusDevices(_.get(item, 'status', ''))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid mt={2} item xs={12} display="flex">
          <Button
            sx={{
              mr: 2,
            }}
            variant="outlined"
            color="info"
            onClick={onClose}
          >
            Cerrar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryStatusDevice;
