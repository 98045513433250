import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { type FC, Suspense } from 'react';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import GET_DGA_CONFIGURATION from '../../graphql/queries/getDGAConfiguration';
import { Controller, useForm } from 'react-hook-form';
import StandardAutocompleteIds from '@features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { CreateDgaConfigInput, DgaType } from '@/__generated__/graphql';
import CREATE_DGA_CONFIGURATION from '../../graphql/mutations/createDgaConfiguration';
import { Info } from '@mui/icons-material';
import { useDispatch, useSelector } from '@/store';
import {
  setCreateDGAConfigurationModal,
  setUpdateDGAConfigurationModal,
} from '../../slices/dgaSlice';
import { useModalStatusMessage } from '@/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLingui } from '@lingui/react';
import { getDgaConfigurationValidationSchema } from '../../utils/validationSchemas';
import AuthorizationWrapper, { Access } from './AuthorizationWrapper';

type FormValues = CreateDgaConfigInput;

const Component: FC<{ onClose: () => void; deviceId?: string }> = ({
  onClose,
  deviceId: preSelectedDeviceId,
}) => {
  const { _, i18n } = useLingui();
  const dispatch = useDispatch();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const methods = useForm<FormValues>({
    defaultValues: {
      deviceId: preSelectedDeviceId ?? '',
      code: '',
      rut: '',
      password: '',
      type: DgaType.Surface,
    },
    resolver: zodResolver(getDgaConfigurationValidationSchema(i18n)),
  });

  const deviceId = methods.watch('deviceId');

  const { data } = useSuspenseQuery(
    GET_DGA_CONFIGURATION,
    deviceId ? { variables: { deviceId } } : skipToken,
  );

  const [createDgaConfiguration, { loading }] = useMutation(CREATE_DGA_CONFIGURATION, {
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Configuración DGA creada correctamente`));
      onClose();
    },
    update(cache) {
      cache.modify({
        fields: {
          devicesConnection(_, { INVALIDATE }) {
            return INVALIDATE;
          },
          devices(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
      cache.evict({
        id: cache.identify({ __typename: 'Device', id: deviceId }),
      });
    },
    onQueryUpdated(observableQuery) {
      if (observableQuery.queryName !== 'StandardAutocompleteData') {
        return observableQuery.refetch();
      }
    },
  });

  const hasDGAConfiguration = data?.device?.dgaConfiguration != null;

  const resetForm = () => {
    methods.reset();
  };

  const onSubmit = async (values: FormValues) => {
    await createDgaConfiguration({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  const handleEditConfiguration = () => {
    dispatch(
      setCreateDGAConfigurationModal({
        open: false,
      }),
    );
    dispatch(
      setUpdateDGAConfigurationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Controller
          name="deviceId"
          control={methods.control}
          render={({ field, fieldState }) => (
            <StandardAutocompleteIds
              dataType="devices"
              label="Dispositivo"
              onChange={(_, value) => {
                field.onChange(value);
              }}
              value={field.value || null}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {hasDGAConfiguration && !loading ? (
          <Box mt={2}>
            <Box display="flex" gap={1} alignItems="center" mb={1}>
              <Info fontSize="small" color="warning" />
              <Typography>El dispositivo ya tiene una configuración DGA</Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={handleEditConfiguration}>
              Editar configuración
            </Button>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <Controller
              name="code"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Código DGA"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="rut"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="RUT"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="password"
                  label="Contraseña"
                  autoComplete="new-password"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="type"
              control={methods.control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={Object.values(DgaType)}
                  disableClearable
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(value) => {
                    if (value === DgaType.Surface) return 'Superficial';
                    if (value === DgaType.PipeSurface) return 'Superficial con flujómetro';
                    if (value === DgaType.Underground) return 'Subterránea';
                    return value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de extracción"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Box>
        )}
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={resetForm}
        dirtyForm={hasDGAConfiguration ? false : methods.formState.isDirty}
        submitLoading={loading}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
    <ModalActions onClose={onClose} />
  </>
);

const CreateDGAConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { createDGAConfigurationModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setCreateDGAConfigurationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={createDGAConfigurationModal.open} maxWidth="md" fullWidth>
      <ModalTitle onClose={onClose} title={<Trans>Crear configuración DGA</Trans>} />
      <Suspense fallback={<Fallback onClose={onClose} />}>
        <AuthorizationWrapper access={Access.CREATE_DGA_CONFIGURATION_MODAL}>
          <Component onClose={onClose} deviceId={createDGAConfigurationModal.deviceId} />
        </AuthorizationWrapper>
      </Suspense>
    </Dialog>
  );
};

export default CreateDGAConfigurationModal;
