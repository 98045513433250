import { useSuspenseQuery } from '@apollo/client';
import type { FC, PropsWithChildren } from 'react';
import GET_ACCESS_DGA from '@/graphql/querys/access/getAccessDGA';
import { Box } from '@mui/material';

export enum Access {
  CREATE_DGA_CONFIGURATION_MODAL,
}

interface Props extends PropsWithChildren {
  access: Access;
}

const Unauthorized = () => {
  return (
    <Box mx="auto" p={3}>
      <img src="https://images.unsplash.com/photo-1622350428710-c5ecfe29265a?q=80&w=300&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
    </Box>
  );
};

const AuthorizationWrapper: FC<Props> = ({ access, children }) => {
  const { data } = useSuspenseQuery(GET_ACCESS_DGA);

  if (access === Access.CREATE_DGA_CONFIGURATION_MODAL) {
    // return <Unauthorized />;
    if (!data?.authorization?.authorizationDGA?.enableDevices) {
      return <Unauthorized />;
    }
  }

  return children;
};

export default AuthorizationWrapper;
