import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { KanbanLists } from 'src/types/kanbanLists';
import type { AppThunk } from '../store';

interface PostsState {
  showSuppot: boolean;
  columnsKanban: any | null;
  kanbanLists: KanbanLists[];
  comments: any;
  taskId: number | null;
  selectedTags: any;
  listTables: any | null;
  listRows: any | null;
}

const initialState: PostsState = {
  showSuppot: false,
  columnsKanban: [],
  kanbanLists: [],
  comments: [],
  taskId: null,
  selectedTags: [],
  listTables: [],
  listRows: [],
};

const slice = createSlice({
  name: 'posts_news',
  initialState,
  reducers: {
    setShowSuppot(state: PostsState, action: PayloadAction<boolean>): void {
      state.showSuppot = action.payload;
    },
    setColumnsKanban(state: PostsState, action: PayloadAction<any>): void {
      state.columnsKanban = action.payload;
    },
    setKanbanLists(state: PostsState, action: PayloadAction<any>): void {
      state.kanbanLists = action.payload;
    },
    setComments(state: PostsState, action: PayloadAction<any>): void {
      state.comments = action.payload;
    },
    setTaskId(state: PostsState, action: PayloadAction<number | null>): void {
      state.taskId = action.payload;
    },
    setSelectedTags(state: PostsState, action: PayloadAction<any>): void {
      state.selectedTags = action.payload;
    },
    setListTables(state: PostsState, action: PayloadAction<any>): void {
      state.listTables = action.payload;
    },
    setListRows(state: PostsState, action: PayloadAction<any>): void {
      state.listRows = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setShowSuppot =
  (data: boolean | null): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setShowSuppot(data));
  };

export const setColumnsKanban =
  (data: any | null): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setColumnsKanban(data));
  };

export const setKanbanLists =
  (data: any | null): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setKanbanLists(data));
  };

export const setComments =
  (data: any | null): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setComments(data));
  };
export const setTaskId =
  (data: number | null): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setTaskId(data));
  };

export const setSelectedTags =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setSelectedTags(data));
  };

export const setListTables =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setListTables(data));
  };

export const setListRows =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.setListRows(data));
  };
