import { SvgIcon, SvgIconProps } from '@mui/material';

function DataExtractionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m21.42,4.13h-3.64c-.58,0-1.05.47-1.05,1.05s.47,1.05,1.05,1.05h3.5v14.31H2.72V6.23h3.52c.58,0,1.05-.47,1.05-1.05s-.47-1.05-1.05-1.05h-3.67c-1.08,0-1.96.79-1.96,1.77v14.97c0,.98.88,1.77,1.96,1.77h18.84c1.08,0,1.96-.79,1.96-1.77V5.9c0-.98-.88-1.77-1.96-1.77Z" />
      <path d="m11.35,18.22s.04.03.06.05c.13.09.28.14.43.17,0,0,0,0,0,0h0c.05,0,.1.01.15.01.01,0,.03,0,.04,0h0c.21,0,.42-.08.6-.22l3.6-2.79c.27-.21.41-.52.41-.83,0-.22-.07-.45-.22-.64-.35-.46-1.02-.54-1.47-.19l-1.91,1.48V5.18c0-.58-.47-1.05-1.05-1.05s-1.05.47-1.05,1.05v10.08l-1.91-1.48c-.46-.35-1.12-.27-1.47.19-.36.46-.27,1.12.19,1.47l3.59,2.78Z" />
    </SvgIcon>
  );
}

export default DataExtractionIcon;
