import { gql } from '@/__generated__';

const UPDATE_DGA_CONFIGURATION = gql(`#graphql
  mutation UpdateDgaConfiguration($input: UpdateDgaConfigInput!) {
    updateDgaConfiguration(input: $input) {
      id
      code
      rut
      password
      type
      frequency
      hour
      weekday
      day
      enabled
    }
  }
`);

export default UPDATE_DGA_CONFIGURATION;
