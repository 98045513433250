import { gql } from '@/__generated__';

const UPDATE_DEVICE_ALERTS = gql(`#graphql
  mutation UpdateDeviceAlerts(
    $deviceId: String!
    $alertType: AlertTypes!
    $min: Float!
    $max: Float!
    $overflow: Float!
    $unit: String!
) {
    updateDeviceAlerts(
      deviceId: $deviceId
      alertType: $alertType
      min: $min
      max: $max
      overflow: $overflow
      unit: $unit
  )
  }
`);

export default UPDATE_DEVICE_ALERTS;
