import { gql } from '@/__generated__';
import { DeviceList_GetDevicesQuery } from '@/__generated__/graphql';

export type Device = DeviceList_GetDevicesQuery['devicesConnection']['nodes'][number];

export const DEVICE_LIST_DGA_INFO_FRAGMENT = gql(`#graphql
  fragment DeviceList_DgaInfo on Device {
    id
    dgaConfiguration {
      id
      type
    }
    profile {
      type
      name
      sensor
      source
      sourceInfo
      sensor
      status
      organization {
        id
        name
      }
    }
    dataConfiguration {
      deviceId
      state {
        isDry
      }
    }
  }
`);

export const DEVICE_LIST_DGA_CONFIGURATION_FRAGMENT = gql(`#graphql
  fragment DeviceList_DgaConfiguration on Device {
    id
    dgaConfiguration {
      id
      code
      enabled
      frequency
      hour
      weekday
      day
    }
    dgaTicket {
      id
      timestamp
    }
  }
`);

export const DEVICE_LIST_DGA_STATS_FRAGMENT = gql(`#graphql
  fragment DeviceList_DgaStats on Device {
    id
    profile {
      name
      type
      organization {
        id
        name
      }
    }
    dgaStats {
      yesterdaySuccessPercentage
      lastSevenDaysSuccessPercentage
      lastThirtyDaysSuccessPercentage
      percentageBelowMaxFlowInLastMonth
    }
  }
`);

export const DEVICE_LIST_DGA_CHART_FRAGMENT = gql(`#graphql
  fragment DeviceList_DgaChart on Device {
    id
    dgaStats {
      lastThirtyDaysSuccessPercentageByday {
        timestamp
        success_percentage
      }
    }
  }
`);

export const DEVICE_LIST_INFO_FRAGMENT = gql(`#graphql
  fragment DeviceList_Info on Device {
    id
    profile {
      type
      name
      sensor
      source
      sourceInfo
      sensor
      status
      organization {
        id
        name
      }
      irrigation {
        id
        name
      }
    }
    hardwareConfiguration {
      usbExtraction
    }
    alerts {
      lastFlow {
        timestamp
        min
        max
        overflow
        unit
        precision
      }
      lastLevel {
        timestamp
        min
        max
        overflow
        unit
        precision
      }
      distributionLimits {
        maxFlow
        unit
        precision
      }
    }
    dataConfiguration {
      deviceId
      state {
        isDry
      }
    }
    metrics {
      flow {
        lastData
      }
      level {
        lastData
      }
      velocity {
        lastData
      }
    }
  }
`);

export const DEVICE_LIST_METRICS_FRAGMENT = gql(`#graphql
  fragment DeviceList_Metrics on Device {
    id
    profile {
      sensor
    }
    metrics {
      flow {
        lastData
        lastAlertState
        unit
        precision
      }
      level {
        lastData
        lastAlertState
        unit
        precision
      }
      velocity {
        lastData
        lastAlertState
        unit
        precision
      }
      accumulatedVolume {
        lastData
        lastAlertState
        unit
        precision
      }
      angle {
        lastData
        lastAlertState
        unit
        precision
      }
    }
  }
`);

export const DEVICE_LIST_MINICHARTS_FRAGMENT = gql(`#graphql
  fragment DeviceList_Minicharts on Device {
    chartMetrics: metrics(input: $metricsInput) {
      batterySoc {
        data
        unit
        precision
      }
      solarV {
        data
        unit
        precision
      }
    }
    groupedMetrics: metrics(input: $metricsInput, groupOperation: $groupOperation) {
      flow {
        data
        unit
        precision
      }
      level {
        data
        unit
        precision
      }
    }
  }
`);

export const DEVICE_LIST_PICTURE_FRAGMENT = gql(`#graphql
  fragment DeviceList_Picture on Device {
    id
    regis {
      lastPhoto
    }
    profile {
      name
    }
    metrics {
      flow {
        lastData
        lastAlertState
        unit
        precision
      }
      level {
        lastData
        lastAlertState
        unit
        precision
      }
    }
  }
`);

const DEVICE_LIST_GET_DEVICES = gql(`#graphql
  query DeviceList_GetDevices(
    $dgaVariant: Boolean!,
    $input: DevicesInput,
    $pagination: PaginationInput,
    $metricsInput: MetricsParametersInput,
    $groupOperation: MetricsGroupOperationInput,
  ) {
    devicesConnection(input: $input, pagination: $pagination) {
      pageInfo {
        count
        totalCount
        pageNumber
        limit
        offset
      }
      nodes {
        id
        profile {
          id
          type
          status
          source
          sourceInfo
        }
        ...DeviceList_Info @skip(if: $dgaVariant)
        ...DeviceList_Metrics @skip(if: $dgaVariant)
        ...DeviceList_Minicharts @skip(if: $dgaVariant)
        ...DeviceList_Picture @skip(if: $dgaVariant)
        ...DeviceList_DgaInfo @include(if: $dgaVariant)
        ...DeviceList_DgaConfiguration @include(if: $dgaVariant)
        ...DeviceList_DgaStats @include(if: $dgaVariant)
        ...DeviceList_DgaChart @include(if: $dgaVariant)
      }
    }
  }
`);

export default DEVICE_LIST_GET_DEVICES;
